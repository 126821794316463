/** @jsxRuntime classic */
/** @jsx jsx */
import { Heading, Flex, jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Tagline } from 'components/typography/tagline';
import { resolveSize } from 'utils/helpers';

export const Headline = forwardRef((props, ref) => {
  const { pre, tagline, size, ...rest } = props || {};

  const resolvedSize = resolveSize(size);

  return (
    <Flex
      ref={ref}
      sx={{
        marginBottom: [4, 5, 6],
        justifyContent: 'flex-start',
        flexDirection: ['column', null, 'row'],
        alignItems: 'flex-start',
      }}
    >
      {pre || tagline ? (
        <Tagline
          pre={pre}
          tagline={tagline}
          sx={{ flex: '2' }}
          data-scroll
          data-scroll-repeat
        />
      ) : null}
      <Heading
        as="h1"
        variant="h1"
        sx={{ fontSize: resolvedSize, flex: '8' }}
        data-scroll
        data-scroll-repeat
        {...rest}
      />
    </Flex>
  );
});

Headline.propTypes = {
  pre: PropTypes.string,
  tagline: PropTypes.string,
};
