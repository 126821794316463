import sanityClient from '@sanity/client';

export const client = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true, // `false` if you want to ensure fresh data, but requests take longer
});

export const fetch = async (...args) => {
  return await client.fetch(...args);
};

const api = {
  client,
  fetch,
};

export const fetchCompany = `
  *[_type == 'company' && _id == 'company']{...}[0]
`;

export const blocksQuery = `blocks[]{
  'people': people_group[]->{
    ...
  },
  'gridimages': gridimages[]{
    asset->
  },
  site_image{
    'mobileImage': mobileImage{
      asset->{...},
      ...,
    },
    asset->{...},
    ...
  },
  column_group[]{
    body[]{
      _type == "employee" => {
        "employee": @->{...}
      },
      _type == "office_reference" => {
        "office": @->{...}
      },
      ...
    },
    ...
  },
  body[]{
    _type == "employee" => {
      "employee": @->{...}
    },
    _type == "office_reference" => {
      "office": @->{...}
    },
    ...
  },
  video{
    file{
      asset->{...},
    },
    ...
  },
  blocks[]{
    site_image{
      'mobileImage': mobileImage{
        asset->{...},
        ...,
      },
      asset->{...},
      ...
    },
    video{
      file{
        asset->{...},
      },
      ...
    },
    grid_base{
      blocks[]{
        site_image{
          'mobileImage': mobileImage{
            asset->{...},
            ...,
          },
          asset->{...},
          ...
        },
        videoblock_grid{
          "videoasset": file.asset->{...},
          video{
            file{
              asset->{...},
            },
            ...
          },
        },
        ...
      },
      ...
    },
    ...
  },
  ...
},`;

export default api;
