/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const Play = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M21.389 11.36L7.89 3.111a.75.75 0 00-1.141.64v16.498a.75.75 0 001.141.64l13.498-8.25a.751.751 0 000-1.279z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Play;
