import imageUrlBuilder from '@sanity/image-url';
import { client } from 'api';

const builder = imageUrlBuilder(client);

export function urlFor(source) {
  return builder.image(source);
}

export default urlFor;
