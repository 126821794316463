import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useGoogleAnalytics from 'utils/hooks/useGoogleAnalytics';
import { colors } from './theme';

const Home = React.lazy(() => import('components/pages/home'));
const About = React.lazy(() => import('components/pages/about'));
const Career = React.lazy(() => import('components/pages/career'));
const JobOpening = React.lazy(() => import('components/pages/job-opening'));
const Work = React.lazy(() => import('components/pages/work'));
const News = React.lazy(() => import('components/pages/news'));
const NewsPost = React.lazy(() => import('components/pages/post'));
const Project = React.lazy(() => import('components/pages/project'));
const Contact = React.lazy(() => import('components/pages/contact'));
const NotFound = React.lazy(() => import('components/pages/not-found'));

const ROUTES = [
  {
    path: '/',
    key: 'APP_ROOT',
    component: RenderRoutes,
    routes: [
      {
        path: '/',
        key: 'APP_INDEX',
        exact: true,
        component: (props) => <Home {...props} color={colors.white} />,
      },
      {
        path: '/about',
        key: 'ABOUT',
        exact: true,
        component: (props) => <About {...props} color={colors.pink} />,
      },
      {
        path: '/work',
        key: 'WORK_ROOT',
        component: RenderRoutes,
        routes: [
          {
            path: '/work',
            key: 'WORK_INDEX',
            exact: true,
            component: (props) => <Work {...props} color={colors.orange} />,
          },
          {
            path: '/work/:slug',
            key: 'PROJECT_INDEX',
            exact: true,
            component: (props) => <Project {...props} color={colors.white} />,
          },
        ],
      },
      {
        path: '/news',
        key: 'NEWS_ROOT',
        component: RenderRoutes,
        routes: [
          {
            path: '/news',
            key: 'NEWS_INDEX',
            exact: true,
            component: (props) => <News {...props} color={colors.pink} />,
          },
          {
            path: '/news/:slug',
            key: 'POST_INDEX',
            exact: true,
            component: (props) => <NewsPost {...props} color={colors.beige} />,
          },
        ],
      },
      {
        path: '/careers',
        key: 'CAREER_ROOT',
        component: RenderRoutes,
        routes: [
          {
            path: '/careers',
            key: 'CAREER_INDEX',
            exact: true,
            component: (props) => <Career {...props} color={colors.green} />,
          },
          {
            path: '/careers/:slug',
            key: 'JOBOPENING_INDEX',
            exact: true,
            component: (props) => (
              <JobOpening {...props} color={colors.beige} />
            ),
          },
        ],
      },
      {
        path: '/contact',
        key: 'CONTACT',
        exact: true,
        component: (props) => <Contact {...props} color={colors.yellow} />,
      },
    ],
  },
];

export default ROUTES;

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes(route) {
  return (
    <Route path={route.path} exact={route.exact}>
      {(match) => <route.component {...match} routes={route.routes} />}
    </Route>
  );
}

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes({ routes }) {
  useGoogleAnalytics();

  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}

      <Route
        component={(context) => (
          <NotFound color={colors.brandRed} status={404} />
        )}
      />
    </Switch>
  );
}
