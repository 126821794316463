/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { useState } from 'react';
import EmployeeCard from './EmployeeCard';
import ArrowLink from 'components/ui-components/arrow-link';

export const EmployeeListItem = ({
  name,
  image,
  email,
  position,
  phone,
  ...rest
}) => {
  const [showCard, setShowCard] = useState(false);

  return (
    <Flex
      sx={{ flexDirection: 'column' }}
      data-scroll
      data-scroll-repeat
      {...rest}
      onMouseEnter={() => setShowCard(true)}
      onMouseLeave={() => setShowCard(false)}
    >
      {name && (
        <ArrowLink
          as="h4"
          sx={{
            display: ['none', 'initial'],
          }}
        >
          {name}
        </ArrowLink>
      )}
      <EmployeeCard
        name={name}
        image={image}
        position={position}
        email={email}
        phone={phone}
        sx={{
          position: ['initial', null, 'absolute'],
          right: 0,
          top: 0,
          width: ['auto', null, '30%'],
          display: ['initial', null, showCard ? 'block' : 'none'],
          // pointerEvents: 'all',
          zIndex: '10',
        }}
      />
    </Flex>
  );
};

export default EmployeeListItem;
