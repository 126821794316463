/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useState, Suspense } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Cursor from 'components/ui-components/cursor';
import ErrorBoundary from 'components/pages/error-boundary';
import ROUTES, { RenderRoutes } from './routes';
import useSWR from 'swr';
import { LogError } from 'business/constants';
import WebFont from 'webfontloader';
import LoadingSpinner from 'components/ui-components/loading-spinner';
import urlFor from 'utils/helpers/image-url';
import { buildImageObj } from 'utils/helpers';

const SEO = React.lazy(() => import('components/utility-wrappers/seo'));
const Header = React.lazy(() => import('components/ui-components/header'));
const history = createBrowserHistory();

const request = {
  params: {},
  query: `{
    'site_settings':*[_type == 'site_settings' && _id == 'site_settings' && !(_id in path("drafts.**"))]{
      seo
    }[0]
  }`,
};

// TODO: Context object with previous background color, on page load animate from prev to next.
function App() {
  const { data, error } = useSWR([request.query.toString(), request.params]);
  const [isFontLoaded, setIsFontLoaded] = useState(false);
  const { theme = {} } = useThemeUI();
  const { regular, medium, semibold, bold } = theme.fonts;

  WebFont.load({
    custom: {
      families: [regular, medium, semibold, bold],
    },
    events: true,
    classes: false,
    active: function () {
      setIsFontLoaded(true);
    },
  });

  if (error || !data) {
    error && LogError(request.query, request.params, error);
    return null;
  }

  if (!isFontLoaded) {
    return null;
  }

  const { site_settings = {} } = data;
  let imageUrl;

  if (site_settings?.seo?.image) {
    imageUrl = urlFor(buildImageObj(site_settings.seo?.image))
      .auto('format')
      .url();
  }

  return (
    <React.Fragment>
      <SEO {...site_settings.seo}>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org/',
            '@id': 'https://sunnyatsea.se',
            '@type': 'LocalBusiness',
            name: 'Sunny at Sea',
            url: location.href,
            telephone: '+46 70 791 13 73',
            address: {
              '@type': 'PostalAddress',
              addressLocality: 'Stockholm',
              adressRegion: 'Stockholm',
              addressCountry: 'SE',
              postalCode: '112 34',
              streetAddress: 'Sankt Eriksgatan 48F',
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: '59.335330046810704',
              longitude: '18.03394908008177',
            },
            openingHoursSpecification: [
              {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                ],
                opens: '09:00',
                closes: '18:00',
              },
            ],
            ...(imageUrl ? { image: [imageUrl] } : []),
          })}
        </script>
      </SEO>
      <Router history={history}>
        <Header />
        <ErrorBoundary>
          <Suspense fallback={<LoadingSpinner />}>
            <RenderRoutes routes={ROUTES} />
          </Suspense>
        </ErrorBoundary>
        <Cursor />
      </Router>
    </React.Fragment>
  );
}

export default App;
