/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Link } from 'theme-ui';
import { Fragment } from 'react';

function EmployeeContact({ employee, ...props }) {
  const { email, name, phone, position } = employee || {};

  return (
    <Text as="p" variant="body" {...props}>
      {name && (
        <Fragment>
          {name}
          <br />
        </Fragment>
      )}
      {position && (
        <Fragment>
          / {position}
          <br />
        </Fragment>
      )}
      {phone && (
        <Fragment>
          <Link href={`tel:${phone}`}>{phone}</Link>
          <br />
        </Fragment>
      )}
      {email && <Link href={`mailto:${email}`}>{email}</Link>}
    </Text>
  );
}

export default EmployeeContact;
