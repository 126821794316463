/** @jsxRuntime classic */
/** @jsx jsx */
import { forwardRef } from 'react';
import { jsx, Container } from 'theme-ui';

// Container with max-width
const ContentLayout = forwardRef((props, ref) => {
  return <Container ref={ref} {...props} />;
});

export default ContentLayout;
