/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui';
import BlockContent from 'components/blocks/block-content';
import { Headline } from 'components/typography';
import ContentLayout from 'components/ui-components/layout/ContentLayout';

const ColumnContentBlock = (props) => {
  return (
    <ContentLayout
      sx={{
        paddingX: [0, `${100 / 12}vw`],
        maxWidth: 'none',
      }}
    >
      {props.headline && (
        <Headline
          data-scroll
          data-scroll-repeat
          variant="h2"
          as="h2"
          pre={props.headline.decorator}
          tagline={props.headline.tagline}
        >
          {props.headline.headline}
        </Headline>
      )}
      {props.column_group ? (
        <Grid
          gap={0}
          sx={{
            gridTemplateColumns: [
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
            ],
            margin: props.verticalspacing + ' 0',
          }}
        >
          {props.column_group.map((column, index) => (
            <Box key={index} pr={3}>
              <BlockContent blocks={column.body || []} />
            </Box>
          ))}
        </Grid>
      ) : null}
    </ContentLayout>
  );
};

export default ColumnContentBlock;
