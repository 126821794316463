/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { GRID_COLUMNS, GRID_ROWS } from 'utils/constants';

export default function FullpageGrid({
  columns = GRID_COLUMNS,
  rows = GRID_ROWS,
  ...props
}) {
  return (
    <Grid
      sx={{
        gridTemplateColumns: [
          `repeat(${GRID_COLUMNS}, 1fr)`,
          null,
          `repeat(${columns}, 1fr)`,
        ],
        gridTemplateRows: rows
          ? [null, null, `repeat(${rows}, ${100 / columns}vw)`]
          : 'auto',
        gap: 0,
        position: 'relative',
      }}
      {...props}
    />
  );
}
