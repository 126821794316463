/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

// flex: flex-grow flex-shrink flex-basis|auto|initial|inherit;
// flex: 'auto' | Same as 1 1 auto.
export const Spacer = ({ size, ...props }) => (
  <div
    sx={
      size ? { flexGrow: 1, flexShrink: 0, flexBasis: size } : { flex: 'auto' }
    }
    {...props}
  ></div>
);

export default Spacer;
