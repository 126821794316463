/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const CornersOut = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.75 4.5h3.75v3.75M8.25 19.5H4.5v-3.75M19.5 15.75v3.75h-3.75M4.5 8.25V4.5h3.75"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CornersOut;
