/** @jsxRuntime classic */
/** @jsx jsx */
import { Img } from 'components/ui-components/image';
import gsap from 'gsap';
import { Box, jsx } from 'theme-ui';
import { GRID_COLUMNS, GRID_ROWS } from 'utils/constants';
import { createBlocks as renderBlocks } from './createBlocks';

// combines array of classNames into a string
export function cn(...args) {
  return args.filter(Boolean).join(' ');
}

export const lerp = (a, b, n) => {
  return (1 - n) * a + n * b;
};

export const map = (x, in_min, in_max, out_min, out_max) => {
  return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export const changeBgColor = (color, rootColor) => {
  const baseSettings = { duration: 2 };
  const rootElement = document.querySelector(`#root`);
  const bodyElement = document.body;

  if (rootElement) {
    gsap.to(rootElement, {
      ...baseSettings,
      backgroundColor: rootColor || color,
    });
  }

  if (!color) {
    gsap.to(bodyElement, { ...baseSettings, backgroundColor: '#E8E0D8' });
    return;
  }

  bodyElement &&
    gsap.to(bodyElement, { ...baseSettings, backgroundColor: color });
};

export const changeHeaderColor = (color) => {
  const baseSettings = { duration: 2 };
  const headerElement = document.querySelector(`nav#js-mainheader`);
  const previousColor = headerElement?.style?.color;

  if (!headerElement || !color) {
    return;
  }
  gsap.to(headerElement, {
    ...baseSettings,
    color: color,
  });

  return previousColor;
};

export const toDoubleDigitString = (number) => {
  return number < 9 ? `0${number}` : `${number}`;
};

export function buildImageObj(source = {}) {
  const imageObj = {
    asset: { _ref: source.asset?._ref || source.asset?._id || '' },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export const getPaddingFromAspectRatio = (width, aspectRatio) => {
  return `${(width / aspectRatio / width) * 100}%`;
};

export const getGridStyles = (grid_alignment, fullWidthMobile) => {
  if (!grid_alignment) return null;
  const { row_start, col_start, row_end, col_end } = grid_alignment;

  if (fullWidthMobile) {
    return {
      gridRowStart: [null, row_start || 'auto'],
      gridRowEnd: ['auto', row_end + 1 || 'auto'],
      gridColumnStart: [2, col_start || 'auto'],
      gridColumnEnd: [GRID_COLUMNS, col_end + 1 || 'auto'],
    };
  }
  return {
    gridRowStart: ['auto', null, row_start] || 'auto',
    gridRowEnd: ['auto', null, row_end + 1 || 'auto'],
    gridColumnStart: [2, null, col_start || 'auto'],
    gridColumnEnd: [GRID_COLUMNS, null, col_end + 1 || 'auto'],
  };
};

export const GridToPosition = (
  gridSettings,
  columns = GRID_COLUMNS,
  rows = GRID_ROWS,
) => {
  const { col_end, col_start, row_end, row_start } = gridSettings || {};
  const colUnit = 100 / columns;
  const rowUnit = 100 / rows;
  const styles = {
    top: row_start ? (row_start - 1) * rowUnit + '%' : 0,
    left: col_start ? (col_start - 1) * colUnit + '%' : 0,
    width:
      col_end && col_start ? (1 + col_end - col_start) * colUnit + '%' : 'auto',
    height:
      row_end && row_start ? (1 + row_end - row_start) * rowUnit + '%' : 'auto',
  };
  return styles;
};

export const createPositionedGridItems = ({ items, columns, rows }) => {
  if (!items) return null;
  return items.map((item, i) => {
    const { _key, site_image, grid_alignment, scrollspeed } = item;
    const positions = GridToPosition(grid_alignment, columns, rows);

    return (
      <Box
        data-scroll
        data-scroll-speed={scrollspeed || i + 1}
        sx={{ position: 'absolute', ...positions }}
        key={_key}
      >
        <Img
          key={_key}
          asset={site_image}
          width={1000}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
    );
  });
};

export const resolveSize = (size) => {
  switch (size) {
    case 'big': {
      return [6, 7, 9, 11];
    }
    case 'small': {
      return [5, 6, 7, 9];
    }
    default: {
      return [6, 7, 9, 11];
    }
  }
};

export const createBlocks = renderBlocks;

const Helpers = {
  cn,
  lerp,
  map,
  buildImageObj,
  createBlocks,
};

export default Helpers;
