/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from 'theme-ui';
import { Fragment } from 'react';

function OfficeInfo({ office, ...rest }) {
  if (!office) return null;

  const { city, postcode, street_adress } = office || {};

  return (
    <Text as="p" variant="body" {...rest}>
      {city && (
        <Fragment>
          {city} office
          <br />
        </Fragment>
      )}
      {street_adress && (
        <Fragment>
          {street_adress}
          {postcode && <br />}
        </Fragment>
      )}
      {postcode} {city}
    </Text>
  );
}

export default OfficeInfo;
