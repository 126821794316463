/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import VideoSettings from './VideoSettings';
import { forwardRef } from 'react';

export const ControlButton = forwardRef((props, ref) => (
  <button
    ref={ref}
    sx={{
      backgroundColor: 'transparent',
      outline: 'none',
      border: 'none',
      color: 'white',
      display: 'block',
      padding: 1,
      borderRadius: 'sm',
      cursor: 'pointer',
      flexShrink: 0,
      flexBasis: `${VideoSettings.iconSize}px`,
      transition: 'all 0.15s ease',

      ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
        color: 'brand.primary.500',
      },
    }}
    {...props}
  />
));

export default ControlButton;
