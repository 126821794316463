/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import HamburgerIcon from './hamburger';
import LogoIcon from './logo';
import SpinnerIcon from './spinner';
import ArrowIcon from './arrow';
export { Pause } from './Pause.js';
export { CornersOut } from './CornersOut.js';
export { SpeakerLow } from './SpeakerLow.js';
export { SpeakerHigh } from './SpeakerHigh.js';
export { SpeakerSlash } from './SpeakerSlash.js';
export { PictureInPicture } from './PictureInPicture.js';
export { Play } from './Play.js';
export { PlayCircle } from './PlayCircle.js';

/**
 *
 * @param {String} symbol a string indicating which icon to use
 */
function Icon(props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon {...props} />;
    case 'logo':
      return <LogoIcon {...props} />;
    case 'spinner':
      return <SpinnerIcon {...props} />;
    case 'arrow':
      return <ArrowIcon {...props} />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
