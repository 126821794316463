/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import ContentLayout from 'components/ui-components/layout/ContentLayout';
import EmployeeListItem from './EmployeeListItem';

const PeopleListBlock = ({ people, ...rest }) => {
  return (
    <ContentLayout
      my={[5, 6]}
      sx={{ minHeight: 550, paddingX: [0, `${100 / 12}vw`], maxWidth: 'none' }}
      {...rest}
    >
      <Flex
        sx={{
          position: 'relative',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {people &&
          people.map((person, index) => {
            return <EmployeeListItem {...person} key={index} />;
          })}
      </Flex>
    </ContentLayout>
  );
};

export default PeopleListBlock;
