/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Box, Link } from 'theme-ui';
import Image from 'components/ui-components/image';
import { Fragment, useEffect, useState } from 'react';

export const EmployeeCard = ({
  name,
  image,
  position,
  phone,
  email,
  imageProps,
  key,
  sx,
  ...rest
}) => {
  const [bodyBg, setBodyBg] = useState('yellow');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBodyBg(document.body.style.backgroundColor);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box key={key} {...rest}>
      {image && (
        <Box
          sx={{
            backgroundColor: bodyBg,
          }}
        >
          <Image
            alt={image?.alt || `Image of ${name}`}
            image_asset={image}
            width={1200}
            height={1200}
            format="square"
            grayscale={true}
            sx={{
              marginBottom: 3,
              mixBlendMode: 'multiply',
              width: '100%',
            }}
            data-scroll="false"
            data-scroll-repeat="false"
            {...imageProps}
          />
        </Box>
      )}
      <Text variant="body">
        {position && `/ ${position}`}
        {phone && (
          <Fragment>
            <br />
            <Link
              href={`tel:` + phone}
              sx={{ pointerEvents: 'initial' }}
              target="_blank"
            >
              {phone}
            </Link>
          </Fragment>
        )}
        {email && (
          <Fragment>
            <br />
            <Link
              href={`mailto:` + email}
              sx={{ pointerEvents: 'initial' }}
              target="_blank"
            >
              {email}
            </Link>
          </Fragment>
        )}
        {name && (
          <Fragment>
            <br />
            {name}
          </Fragment>
        )}
      </Text>
    </Box>
  );
};

export default EmployeeCard;
