/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/ui-components/link';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Called after the link is clicked and before the delay timer starts.
 *
 * @callback onDelayStart
 * @param {number} e click event
 * @param {string} to link to navigate to
 */

/**
 * Called after the delay timer ends.
 *
 * @callback onDelayEnd
 * @param {number} e click event
 * @param {string} to link to navigate to
 */

/**
 * Link component that delays page navigation
 *
 * @param {number} delay - Milliseconds to wait before registering the click.
 * @param {bool} replace - Whether to replace history or not.
 * @param {string} to - Link to navigate to.
 * @param {onDelayStart} onDelayStart - Called after the link is clicked and before the delay timer starts.
 * @param {onDelayEnd} onDelayEnd - Called after the delay timer ends.
 */
export const DelayLink = forwardRef((props, ref) => {
  const { delay, onDelayStart, onDelayEnd, replace, to, ...rest } = props;
  let timeout = null;
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);

  const handleClick = (e) => {
    // if trying to navigate to current page stop everything
    if (location?.pathname === to) return;

    onDelayStart(e, to);

    if (e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    timeout = setTimeout(() => {
      if (replace) {
        history.replace(to);
      } else {
        history.push(to);
      }

      onDelayEnd(e, to);
    }, delay);
  };

  return <Link {...rest} ref={ref} to={to} onClick={handleClick} />;
});

DelayLink.propTypes = {
  delay: PropTypes.number,
  onDelayStart: PropTypes.func,
  onDelayEnd: PropTypes.func,
  replace: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

DelayLink.defaultProps = {
  delay: 0,
  onDelayStart: () => {},
  onDelayEnd: () => {},
  replace: false,
};

export default DelayLink;
