import api from 'api';
import LoadingSpinner from 'components/ui-components/loading-spinner';
import React, { Suspense } from 'react';
import { hydrate, render } from 'react-dom';
import 'styles/fonts.scss';
import { SWRConfig } from 'swr';
import { ThemeProvider } from 'theme-ui';
import App from './app';
import './global.scss';
import * as serviceWorker from './serviceWorker';
import theme from './theme';

// swr is used to fetch data from Sanity.
const swrConfig = {
  revalidateOnFocus: false,
  suspense: true,
  fetcher: api.fetch,
};

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LoadingSpinner />}>
        <SWRConfig value={swrConfig}>
          <App />
        </SWRConfig>
      </Suspense>
    </ThemeProvider>,
    rootElement,
  );
} else {
  render(
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LoadingSpinner />}>
        <SWRConfig value={swrConfig}>
          <App />
        </SWRConfig>
      </Suspense>
    </ThemeProvider>,
    rootElement,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
