/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { buildImageObj } from 'utils/helpers';
import { urlFor } from 'utils/helpers/image-url';

function Figure(props) {
  return (
    <figure
      sx={{
        margin: '2rem 0',
        padding: 0,
      }}
    >
      {props.asset && (
        <img
          src={urlFor(buildImageObj(props)).width(1200).url()}
          alt={props.alt}
          sx={{
            width: '100%',
            height: 'auto',
          }}
        />
      )}
      <figcaption
        sx={{
          marginTop: '0.5rem',
        }}
      >
        {props.caption}
      </figcaption>
    </figure>
  );
}

export default Figure;
