/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types';
import { Inline, Stack } from 'raam';
import { useRef } from 'react';
import { Flex, jsx, useThemeUI } from 'theme-ui';
import { useEventListener } from 'utils/hooks/useEventListener';
import {
  CornersOut,
  Pause,
  PictureInPicture,
  Play,
  SpeakerHigh,
  SpeakerLow,
  SpeakerSlash,
} from '../icon';
import { Spacer } from '../spacer';
import { ControlButton } from './ControlButton';
import {
  ProgressBar,
  ToolTip,
  TrackContainer,
  TrackProgress,
} from './ProgressBar';
import {
  handleSeek,
  jumpToTime,
  setupControls,
  toggleMute,
  togglePip,
  updateProgress,
  updateTimeElapsed,
  updateVolume,
} from './utilities';
import { VideoStyles } from './VideoSettings';

const rangeInputStyles = {
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  height: VideoStyles.trackHeight,
  background: 'transparent',
  cursor: 'pointer',
  maxWidth: 'none',

  '&:focus': {
    outline: 'none',
    '&::-webkit-slider-runnable-track': {
      background: 'transparent',
    },
    '&::-moz-range-track': {
      outline: 'none',
    },
  },

  '&::-webkit-slider-runnable-track': {
    width: '100%',
    cursor: 'pointer',
    borderRadius: '1.3px',
    WebkitAppearance: 'none',
    transition: 'all 0.4s ease',
  },

  '&::-moz-range-track': {
    width: '100%',
    height: VideoStyles.trackHeight,
    cursor: 'pointer',
    border: '1px solid transparent',
    background: 'transparent',
    borderRadius: '1.3px',
  },

  '&::-webkit-slider-thumb': {
    height: VideoStyles.thumbSize,
    width: VideoStyles.thumbSize,
    borderRadius: 'full',
    background: 'white',
    cursor: 'pointer',
    WebkitAppearance: 'none',
    marginLeft: '-1px',
  },

  '&::-moz-range-thumb': {
    height: VideoStyles.thumbSize,
    width: VideoStyles.thumbSize,
    borderRadius: 'full',
    border: 'none',
    background: 'white',
    cursor: 'pointer',
    marginTop: '5px',
  },
};

export const VideoControls = ({
  videoEl,
  state,
  storyboard,
  toggleFullScreen,
  showControls,
  hideControls,
  togglePlay,
  ...props
}) => {
  const volumeSliderRef = useRef(null);
  const fullScreenButtonRef = useRef(null);
  const pipButtonRef = useRef(null);
  const controlsContainerRef = useRef(null);
  const seekTooltipRef = useRef(null);
  const seekToolTipTextRef = useRef(null);
  const seekToolTipImageRef = useRef(null);
  const elapsedTimeRef = useRef(null);
  const progressBarRef = useRef(null);
  const seekRef = useRef(null);
  const durationRef = useRef(null);

  useEventListener(
    'click',
    () =>
      togglePip({
        videoEl,
        pipButtonEl: pipButtonRef.current,
      }),
    pipButtonRef.current,
  );

  useEventListener(
    'timeupdate',
    () =>
      updateTimeElapsed({
        videoEl: videoEl,
        elapsedTimeEl: elapsedTimeRef.current,
      }),
    videoEl,
  );

  useEventListener(
    'loadedmetadata',
    () => {
      setupControls({
        videoEl: videoEl,
        durationEl: durationRef.current,
        progressBarEl: progressBarRef.current,
      });
    },
    videoEl,
  );

  useEventListener(
    'timeupdate',
    () =>
      updateProgress({
        videoEl: videoEl,
        progressBarEl: progressBarRef.current,
        seekEl: seekRef.current,
      }),
    videoEl,
  );

  useEventListener(
    'mousemove',
    (event) =>
      handleSeek({
        progressBar: progressBarRef.current,
        seekTooltipEl: seekTooltipRef.current,
        seekToolTipTextEl: seekToolTipTextRef.current,
        seekToolTipImageEl: seekToolTipImageRef.current,
        seekToolTipImageTiles: storyboard?.tiles,
        event,
      }),
    progressBarRef.current,
  );

  useEventListener(
    'click',
    () =>
      jumpToTime({
        progressBarEl: progressBarRef.current,
        videoEl,
      }),
    progressBarRef.current,
  );

  useEventListener(
    'input',
    () =>
      updateVolume({
        videoEl,
        volumeSlider: volumeSliderRef.current,
        volume: volumeSliderRef.current,
      }),
    volumeSliderRef.current,
  );

  useEventListener(
    'mouseenter',
    () => showControls(),
    controlsContainerRef.current,
  );

  useEventListener(
    'mouseleave',
    () => hideControls(),
    controlsContainerRef.current,
  );

  const { theme = {} } = useThemeUI();

  const controlsVisible = state.controlsVisible && state.isPlaying;

  return (
    <Stack
      ref={controlsContainerRef}
      gap={2}
      sx={{
        backgroundColor: 'alpha.black.500',
        right: '0',
        left: '0',
        bottom: '0',
        padding: [2],
        position: 'absolute',
        transition: 'all 0.2s ease',
        color: 'white',
        pointerEvents: controlsVisible ? 'initial' : 'none',
        opacity: controlsVisible ? 1 : 0,
        fontSize: 1,
        ...(theme.video?.controlsContainer || {}),
      }}
      {...props}
    >
      <ProgressBar ref={progressBarRef}>
        <TrackContainer>
          <TrackProgress ref={seekRef} />
          <ToolTip ref={seekTooltipRef}>
            {storyboard && (
              <div
                ref={seekToolTipImageRef}
                role="img"
                alt="timeline preview"
                sx={{
                  height: storyboard.tile_height,
                  width: storyboard.tile_width,
                  background: `url(${storyboard.url}) 0 0`,
                }}
              />
            )}
            <pre ref={seekToolTipTextRef}></pre>
          </ToolTip>
        </TrackContainer>
      </ProgressBar>
      <Flex alignItems="center">
        <Inline gap={3} alignItems="center" flexShrink={0}>
          <ControlButton
            onClick={() => togglePlay(videoEl)}
            aria-label="Toggle Play/Pause"
          >
            {state.isPlaying ? (
              <Pause
                width={theme.video?.icon?.size || 20}
                height={theme.video?.icon?.size || 20}
                sx={{ display: 'block' }}
              />
            ) : (
              <Play
                width={theme.video?.icon?.size || 20}
                height={theme.video?.icon?.size || 20}
                sx={{ display: 'block' }}
              />
            )}
          </ControlButton>
          <pre id="time">
            <time id="time-elapsed" ref={elapsedTimeRef}>
              00:00
            </time>
            <span sx={{ opacity: 0.5 }}> / </span>
            <time id="duration" ref={durationRef} sx={{ opacity: 0.5 }}>
              00:00
            </time>
          </pre>
        </Inline>
        <Spacer />
        <Inline
          gap={3}
          alignItems="center"
          flexShrink={0}
          sx={{ overflow: 'visible' }} // to make volume slider thumb not clip
        >
          <Flex
            id="volume-controls"
            sx={{ alignItems: 'center', justifyContent: 'space-around' }}
          >
            <input
              ref={volumeSliderRef}
              id="volume"
              type="range"
              max="1"
              min="0"
              step="0.01"
              sx={{
                ...rangeInputStyles,

                backgroundColor: 'alpha.white.200',
                borderRadius: 'sm',
                overflow: 'visible',
              }}
            ></input>
            <Spacer size={2} />
            <ControlButton
              onClick={() => {
                toggleMute({
                  video: videoEl,
                  volumeSlider: volumeSliderRef.current,
                });
              }}
              aria-label="Toggle Mute"
            >
              {!videoEl.muted && state.volume > 0 && state.volume <= 0.5 && (
                <SpeakerLow
                  width={theme.video?.icon?.size || 20}
                  height={theme.video?.icon?.size || 20}
                  sx={{ display: 'block' }}
                />
              )}
              {!videoEl.muted && videoEl.volume > 0.5 && (
                <SpeakerHigh
                  width={theme.video?.icon?.size || 20}
                  height={theme.video?.icon?.size || 20}
                  sx={{ display: 'block' }}
                />
              )}
              {(videoEl.muted || state.volume === 0) && (
                <SpeakerSlash
                  width={theme.video?.icon?.size || 20}
                  height={theme.video?.icon?.size || 20}
                  sx={{ display: 'block' }}
                />
              )}
            </ControlButton>
          </Flex>
          {!('pictureInPictureEnabled' in document) && (
            <ControlButton
              ref={pipButtonRef}
              aria-label="Toggle Picture in Picture Mode"
            >
              <PictureInPicture
                width={theme.video?.icon?.size || 20}
                height={theme.video?.icon?.size || 20}
                sx={{ display: 'block' }}
              />
            </ControlButton>
          )}
          <ControlButton
            ref={fullScreenButtonRef}
            onClick={toggleFullScreen}
            aria-label="Toggle Fullscreen"
          >
            <CornersOut
              width={theme.video?.icon?.size || 20}
              height={theme.video?.icon?.size || 20}
              sx={{ display: 'block' }}
            />
          </ControlButton>
        </Inline>
      </Flex>
    </Stack>
  );
};

export default VideoControls;

VideoControls.defaultProps = { autoload: true };

VideoControls.propTypes = {
  videoEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.instanceOf(Element),
  ]),
  state: PropTypes.shape({
    isPlaying: PropTypes.bool,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    volume: PropTypes.number,
    controlsVisible: PropTypes.bool,
    useCustomControls: PropTypes.bool,
  }),
  storyboard: PropTypes.shape({
    duration: PropTypes.number,
    tile_height: PropTypes.number,
    tile_width: PropTypes.number,
    tiles: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    ),
    url: PropTypes.string,
  }),
  toggleFullScreen: PropTypes.func,
  hideControls: PropTypes.func,
  showControls: PropTypes.func,
  togglePlay: PropTypes.func,
};
