/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import { forwardRef } from 'react';

export const TrackContainer = forwardRef((props, ref) => {
  const { theme = {} } = useThemeUI();

  return (
    <div
      ref={ref}
      sx={{
        position: 'relative',
        backgroundColor: 'rgba(255,255,255,0.2)',
        borderRadius: '3px',
        flexBasis: '4px',
        width: '100%',
        ...(theme.video?.progressBar?.track?.container || {}),
      }}
      {...props}
    />
  );
});

export default TrackContainer;
