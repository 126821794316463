/** @jsxRuntime classic */
/** @jsx jsx */
import { gsap } from 'gsap';
import throttle from 'lodash/throttle';
import { useEffect, useRef } from 'react';
import { jsx } from 'theme-ui';
import useEventListener from 'utils/hooks/useEventListener';

export default function Cursor() {
  const clientX = useRef(window.innerWidth / 2);
  const clientY = useRef(window.innerHeight / 2);
  const isSmall = useRef(false);
  const cursorRef = useRef(null);

  const handleMove = (e) => {
    clientX.current = e.clientX;
    clientY.current = e.clientY;
  };

  const debouncedHandleMove = throttle((e) => handleMove(e), 50);

  useEventListener('mousemove', (e) => debouncedHandleMove(e));

  const handleClick = () => {
    isSmall.current = !isSmall.current;
    setTimeout(() => {
      isSmall.current = !isSmall.current;
    }, 100);
  };

  useEventListener('click', handleClick);

  useEffect(() => {
    const initCursor = () => {
      const render = () => {
        gsap.to(cursorRef.current, {
          duration: 0.45,
          x: clientX.current,
          y: clientY.current,
          ease: 'Power2.easeOut',
          scale: !isSmall.current ? 1 : 2,
        });

        requestAnimationFrame(render);
      };
      requestAnimationFrame(render);
    };

    initCursor();
  }, [clientX, clientY, isSmall]);

  return (
    <div
      ref={cursorRef}
      id="cursor"
      sx={{
        display: ['none', null, 'initial'],
        position: 'fixed',
        pointerEvents: 'none',
        width: '1rem',
        height: '1rem',
        left: '-0.5rem',
        top: '-0.5rem',
        borderRadius: '50%',
        zIndex: 11000,
        backgroundColor: 'black',
      }}
    />
  );
}
