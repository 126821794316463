/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Link } from 'theme-ui';
import { Fragment } from 'react';
import useSWR from 'swr';
import { fetchCompany } from 'api';

const request = {
  params: {},
  query: fetchCompany,
};

function SocialLinks(props) {
  const { data, error } = useSWR([request.query.toString(), request.params]);

  if (!data || error) return <Fragment />;

  const { social } = data;

  return (
    <Text as="p" variant="body" {...props}>
      {social &&
        social.map((account, i) => (
          <Fragment key={account._key}>
            <Link href={account.link}>{account.name}</Link>
            {i !== social.length - 1 && <br />}
          </Fragment>
        ))}
    </Text>
  );
}

export default SocialLinks;
