/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Link as ThemedLink } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';
import isExternal from 'is-url-external';
import PropTypes from 'prop-types';
import { fadeOut } from 'utils/hooks/useFadeIn';
import DelayLink from 'components/ui-components/delay-link';
import gsap from 'gsap';
import VanillaTilt from 'vanilla-tilt';
import { useRef, useEffect, forwardRef } from 'react';

const handleMouseEnter = (ref, skew) => {
  if (!ref || !skew) return;

  gsap.fromTo(
    ref,
    {
      scale: 1,
      ease: 'Power2.easeinout',
    },
    {
      duration: 0.4,
      scale: 1.05,
      ease: 'Power2.easeinout',
    },
  );
};

const handleMouseLeave = (ref, skew) => {
  if (!ref || !skew) return;

  gsap.to(ref, {
    duration: 0.4,
    scale: 1,
    ease: 'Power2.easeinout',
  });
};

/**
 * The main link component, if the link is internal return a RouterLink otherwise return a ThemedLink
 * @param {bool} [skew=true] whether or not to apply a transform skew on hover
 * @param {string} to where to navigate to
 */
export const Link = forwardRef(
  ({ to, skew = false, skewMax = 15, ...props }, ref) => {
    const linkRef = useRef(null);
    const linkContainerRef = useRef(null);

    const linkAtts = {
      ref: linkRef,
      rel: 'noopener noreferrer',
    };

    const linkContainerAtts = {
      ref: linkContainerRef,
      onMouseEnter: () => handleMouseEnter(linkContainerRef.current, skew),
      onMouseLeave: () => handleMouseLeave(linkContainerRef.current, skew),
    };

    useEffect(() => {
      if (!skew) return;
      const tiltNode = linkRef.current;
      VanillaTilt.init(tiltNode, {
        max: skewMax,
        speed: 900,
        glare: false,
        reverse: true,
      });

      return () => tiltNode.vanillaTilt.destroy();
    }, [skew, skewMax]);

    return isExternal(to) ? (
      <Box as="span" ref={linkContainerRef} {...linkContainerAtts}>
        <ThemedLink
          ref={ref}
          href={props.to}
          target="_blank"
          {...linkAtts}
          {...props}
        />
      </Box>
    ) : (
      <Box as="span" ref={linkContainerRef} {...linkContainerAtts}>
        <RouterLink ref={ref} to={to} {...linkAtts} {...props} />
      </Box>
    );
  },
);

Link.propTypes = {
  to: PropTypes.string.isRequired,
  skew: PropTypes.bool,
};

/**
 * A delaylink with preset delay and callback
 * @param {string} to where to navigate to
 */
export const TransitionLink = ({ to, ...props }) => {
  return <DelayLink onDelayStart={fadeOut} delay={1000} to={to} {...props} />;
};

export default Link;
