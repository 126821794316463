/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { buildImageObj } from 'utils/helpers';
import urlFor from 'utils/helpers/image-url';

function InlineIcon({ alt, image }) {
  if (!image) return null;
  const imageUrl = urlFor(buildImageObj(image))
    .fit('crop')
    .auto('format')
    .url();
  return (
    <img
      sx={{
        display: 'inline-block',
        margin: 0,
        height: '1.2ch',
      }}
      src={imageUrl}
      alt={alt}
    />
  );
}

export default InlineIcon;
