/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const SpeakerLow = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M7.5 15.75H3a.75.75 0 01-.75-.75V9A.75.75 0 013 8.25h4.5L14.25 3v18L7.5 15.75zM7.5 8.25v7.5M17.869 9.879a3.002 3.002 0 010 4.242"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SpeakerLow;
