/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { Video } from '../../ui-components/video';

const VideoBlock = ({ file, isBackground, ...props }) => {
  const { playbackId, thumbTime } = file?.asset || {};
  const [storyboard, setStoryboard] = useState({});

  useEffect(() => {
    fetch(`https://image.mux.com/${playbackId}/storyboard.json`)
      .then((response) => response.json())
      .then((data) => setStoryboard(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <Video
      src={`https://stream.mux.com/${playbackId}.m3u8`}
      poster={`https://image.mux.com/${playbackId}/thumbnail.jpg${
        thumbTime ? '?time=' + Math.round(thumbTime) : ''
      }`}
      storyboard={storyboard}
      isBackground={isBackground}
      {...props}
    />
  );
};

export default VideoBlock;
