import textStyles from 'components/typography/typography.module.scss';
import breakpoints from 'styles/responsive.scss';
import vars from 'styles/vars.scss';

// Size Unit
export const unit = 'rem';

// Content Max Width
export const contentMaxWidth = vars.contentMaxWidth;

// Colors
export const white = vars.white;
export const black = vars.black;
export const brandYellow = vars.brandYellow;
export const brandPink = vars.brandPink;
export const brandRed = vars.brandRed;
export const brandTeal = vars.brandTeal;
export const brandPurple = vars.brandPurple;
export const brandGreen = vars.brandGreen;

export const color = {
  white: white,
  black: black,
  brandYellow: brandYellow,
  brandPink: brandPink,
  brandRed: brandRed,
  brandTeal: brandTeal,
  brandPurple: brandPurple,
  brandGreen: brandGreen,
};

// Breakpoints
export const breakPoints = {
  mobile: breakpoints.mobile,
  tablet: breakpoints.tablet,
  desktop: breakpoints.desktop,
  widescreen: breakpoints.widescreen,
};

// Fonts
export const font = {
  primary: {
    regular: 'Faktum-Regular',
    medium: 'Faktum-Medium',
    semibold: 'Faktum-SemiBold',
    bold: 'Faktum-Bold',
  },
  mono: 'monospace',
};

// Font Sizes
export const fontSizes = {
  body: {
    default: textStyles.body_default,
    big: textStyles.body_big,
    display: textStyles.body_display,
  },
  headline: {
    default: textStyles.headline_default,
    big: textStyles.headline_big,
    display: textStyles.headline_display,
  },
  tagline: {
    default: textStyles.tagline_default,
  },
};

// Spacing (Paddings/Margins)
export const space = [0, 1, 3, 10];

const styles = {
  bold: 600,
  space,
  color,
  font,
  unit,
  fontSizes,
  breakPoints,
  contentMaxWidth,
};

export default styles;
