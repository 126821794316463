/** @jsxRuntime classic */
/** @jsx jsx */
import BaseBlockContent from '@sanity/block-content-to-react';
import { Divider, Heading, jsx, Link, Text } from 'theme-ui';
import EmployeeContact from './EmployeeContact';
import Figure from './figure';
import InlineIcon from './inline-icon';
import OfficeInfo from './OfficeInfo';
import SocialLinks from './SocialLinks';

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return (
            <Heading
              data-scroll
              data-scroll-repeat
              as="h1"
              variant="h1"
              sx={{
                marginTop: [4, 4, 5],
                marginBottom: [3, 3, 4],
              }}
              {...props}
            />
          );

        case 'h2':
          return (
            <Heading
              data-scroll
              data-scroll-repeat
              as="h2"
              variant="h2"
              sx={{
                marginTop: [4, 4, 5],
                marginBottom: [3, 3, 4],
              }}
              {...props}
            />
          );

        case 'h3':
          return (
            <Heading
              data-scroll
              data-scroll-repeat
              as="h3"
              variant="h3"
              sx={{
                marginTop: [4, 4, 5],
                marginBottom: [3, 3, 4],
              }}
              {...props}
            />
          );

        case 'h4':
          return (
            <Heading
              data-scroll
              data-scroll-repeat
              as="h4"
              variant="h4"
              sx={{
                marginTop: [4, 4, 5],
                marginBottom: [3, 3, 4],
              }}
              {...props}
            />
          );

        case 'h5':
          return (
            <Heading
              data-scroll
              data-scroll-repeat
              as="h5"
              variant="h5"
              sx={{
                marginTop: [4, 4, 5],
                marginBottom: [3, 3, 4],
              }}
              {...props}
            />
          );

        case 'h6':
          return (
            <Heading
              data-scroll
              data-scroll-repeat
              as="h6"
              variant="h6"
              sx={{
                marginTop: [4, 4, 5],
                marginBottom: [3, 3, 4],
              }}
              {...props}
            />
          );

        case 'blockquote':
          return (
            props.children && (
              <blockquote data-scroll data-scroll-repeat>
                {props.children}
              </blockquote>
            )
          );

        case 'normal+':
          return (
            <Text
              data-scroll
              data-scroll-repeat
              sx={{
                marginBottom: [3, 3, 4],
              }}
              {...props}
            />
          );

        case 'preamble':
          return (
            <Text
              data-scroll
              data-scroll-repeat
              as="p"
              variant="preamble"
              sx={{
                marginBottom: [3, 3, 4],
              }}
              {...props}
            />
          );

        case 'display':
          return (
            <Text
              data-scroll
              data-scroll-repeat
              as="p"
              variant="display"
              sx={{
                marginTop: [4, 6],
                marginBottom: [3, 4],
              }}
              {...props}
            />
          );

        default:
          return (
            <Text
              data-scroll
              data-scroll-repeat
              as="p"
              variant="body"
              sx={{ marginBottom: [3, 3, 4] }}
              {...props}
            />
          );
      }
    },
    figure(props) {
      return <Figure {...props.node} />;
    },
    separator() {
      return <Divider />;
    },
    employee(props) {
      return <EmployeeContact data-scroll data-scroll-repeat {...props.node} />;
    },
    office_reference(props) {
      return <OfficeInfo data-scroll data-scroll-repeat {...props.node} />;
    },
    social_links(props) {
      return <SocialLinks data-scroll data-scroll-repeat {...props.node} />;
    },
  },
  marks: {
    inlineicon(props) {
      switch (props.mark?._type) {
        case 'inlineicon':
          if (props.mark) {
            return (
              <InlineIcon
                image={props.mark}
                alt={props.children ? props.children[0] : ''}
              />
            );
          } else {
            return null;
          }
        default:
          return null;
      }
    },
    highlight(props) {
      return <span sx={{ backgroundColor: 'yellow' }} {...props} />;
    },
    link(props) {
      return <Link variant="richtext" href={props?.mark?.href} {...props} />;
    },
    strong(props) {
      return <Text variant="strong" as="strong" {...props} />;
    },
  },
};

const BlockContent = (props) => (
  <BaseBlockContent
    {...props}
    serializers={serializers}
    sx={{
      '& > *': {
        '&:first-child': {
          marginTop: '0 !important',
        },
      },
    }}
  />
);

export default BlockContent;
