/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Heading, Flex, Text, Link, Container } from 'theme-ui';
import React from 'react';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Flex
          sx={{
            minHeight: '100vh',
            width: '100%',
            // backgroundColor: 'background',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: 'calc(100% / 12)',
          }}
        >
          <Container sx={{ maxWidth: ['none', '90rem'] }}>
            <Heading
              as="h2"
              sx={{
                color: 'text',
                marginBottom: [3, null, 4],
                lineHeight: 1.2,
              }}
            >
              Oops, it seems like a part of the application crashed.
            </Heading>
            <Text as="p">
              Please try refreshing the page - If by any chance it still doesnt
              work, we'd appreciate it if you send us an email at{' '}
              <Link href="mailto:info@sunnyatsea.se">info@sunnyatsea.se</Link>
            </Text>
          </Container>
        </Flex>
      );
    }

    return this.props.children;
  }
}
