/** @jsxRuntime classic */
/** @jsx jsx */
import CareerList from 'components/blocks/career-list';
import ColumnContentBlock from 'components/blocks/column-content-block';
import ContentBlock from 'components/blocks/content-block';
import ImageBlock from 'components/blocks/image-block';
import ImageGridBlock from 'components/blocks/image-grid-block';
import PeopleBlock from 'components/blocks/people-contact-block';
import VideoBlock from 'components/blocks/video-block';
import FullpageGrid from 'components/ui-components/fullpage-grid';
import Image from 'components/ui-components/image';
import { Box, jsx } from 'theme-ui';
import { GRID_COLUMNS } from 'utils/constants';
import { getGridStyles, GridToPosition } from 'utils/helpers';
import { GridColumnOverlay, GridRowOverlay } from 'utils/helpers/grid-overlay';

export const BlockContainer = (props) => {
  const {
    grid_alignment,
    scrollspeed,
    children,
    useGrid,
    isScrollSection,
    ...rest
  } = props;
  const positions = grid_alignment ? GridToPosition(grid_alignment) : {};

  // if useGrid is passed, it will not convert to positioned and instead use grid settings normally
  if (useGrid) {
    const gridStyles = grid_alignment ? getGridStyles(grid_alignment) : {};

    return (
      <Box
        as="article"
        sx={{ marginY: `${100 / GRID_COLUMNS / 2}vw`, ...gridStyles }}
        {...rest}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      as="article"
      {...(isScrollSection ? { 'data-scroll-section': true } : {})}
      sx={{
        marginX: [`${100 / GRID_COLUMNS}vw`, null, 0],
        marginY: [`${100 / GRID_COLUMNS / 2}vw`, null, 0],
        position: 'relative',
        ...positions,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

// pass in an array of blocks
export const createBlocks = (
  blocks,
  useGrid = false,
  isScrollSection = true,
) => {
  return blocks.map((block) => {
    const { _type, _key, visibility, grid_alignment, ...rest } = block;
    if (visibility) return null;

    const containerprops = { grid_alignment, useGrid, isScrollSection };

    switch (_type) {
      case 'contentblock_base': {
        return (
          <BlockContainer key={_key} {...containerprops}>
            <ContentBlock {...rest} />
          </BlockContainer>
        );
      }
      case 'columnblock_base': {
        return (
          <BlockContainer key={_key} {...containerprops}>
            <ColumnContentBlock {...rest} />
          </BlockContainer>
        );
      }
      case 'imageblock': {
        return (
          <BlockContainer key={_key} {...containerprops}>
            <ImageBlock {...rest} />
          </BlockContainer>
        );
      }
      case 'imagegrid': {
        return (
          <BlockContainer key={_key} {...containerprops}>
            <ImageGridBlock {...rest} />
          </BlockContainer>
        );
      }
      case 'people_list_block': {
        return (
          <BlockContainer key={_key} {...containerprops}>
            <PeopleBlock {...rest} />
          </BlockContainer>
        );
      }
      case 'video_block': {
        return (
          <BlockContainer key={_key} {...containerprops}>
            <VideoBlock {...rest} />
          </BlockContainer>
        );
      }
      case 'careerlist_base': {
        return (
          <BlockContainer key={_key} {...containerprops}>
            <CareerList {...rest} />
          </BlockContainer>
        );
      }

      // START: Blocks with grid settings
      case 'contentblock_grid': {
        const { grid_alignment, ...props } = rest;
        return (
          <BlockContainer key={_key} {...containerprops}>
            <ContentBlock grid {...props} />
          </BlockContainer>
        );
      }
      case 'imageblock_grid': {
        const { site_image, grid_alignment, scrollspeed, ...props } = rest;
        return (
          <BlockContainer key={_key} {...containerprops}>
            <Image image_asset={site_image} {...props} />
          </BlockContainer>
        );
      }
      case 'grid_base': {
        const { blocks, columns, rows, ...props } = rest;
        return (
          <FullpageGrid
            key={_key}
            columns={columns}
            rows={rows}
            data-scroll-section
            {...props}
          >
            {createBlocks(blocks, true)}
            <GridRowOverlay rows={rows} color="teal" opacity={0.3} size="2px" />
            <GridColumnOverlay
              columns={columns}
              color="teal"
              opacity={0.3}
              size="2px"
              fixed={false}
            />
          </FullpageGrid>
        );
      }
      case 'videoblock_grid': {
        const { grid_alignment, video, ...props } = rest;
        return (
          <BlockContainer
            key={_key}
            grid_alignment={grid_alignment}
            {...containerprops}
          >
            <VideoBlock file={video.file} isBackground={video.isBackground} />
          </BlockContainer>
        );
      }
      // END: Blocks with grid settings
      default: {
        return (
          <h1 key={_key}>Could not find a matching component for {_type}</h1>
        );
      }
    }
  });
};

export default createBlocks;
