/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const PlayCircle = ({
  outerCircleProps,
  hideOuterCircle = false,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 122" {...props}>
    <g fillRule="evenodd">
      <path
        fill="currentColor"
        d="M79.286 56.354L51.84 40.773c-3.62-2.098-7.84.298-7.84 4.493v31.466c0 4.195 4.22 6.595 7.84 4.493l27.446-15.58a5.332 5.332 0 000-9.291z"
      />
      <path
        d="M61.153 0C94.787 0 122 27.212 122 61.153 122 94.787 94.788 122 61.153 122 27.213 122 0 94.788 0 61.153A60.94 60.94 0 0161.153 0zm-.5 7C30.912 7 7 30.917 7 60.653 7 90.084 30.917 114 60.653 114 90.084 114 114 90.083 114 60.653 114 30.912 90.083 7 60.653 7z"
        {...outerCircleProps}
        {...(hideOuterCircle ? { fill: 'none' } : {})}
      />
    </g>
  </svg>
);

export default PlayCircle;
