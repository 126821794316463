/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import ContentLayout from 'components/ui-components/layout/ContentLayout';
import Image from 'components/ui-components/image';

const ImageBlock = (props) => {
  const { site_image, ...rest } = props;
  return (
    <ContentLayout
      sx={{
        marginY: [2, 4, 6],
        paddingX: [0, `${100 / 12}vw`],
        maxWidth: 'none',
      }}
    >
      <Image {...rest} image_asset={site_image} />
    </ContentLayout>
  );
};

export default ImageBlock;
