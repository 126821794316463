/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import { forwardRef } from 'react';

export const MainPlayButton = forwardRef(({ isPlaying, ...props }, ref) => {
  const { theme = {} } = useThemeUI();

  return (
    <button
      tabIndex="0"
      ref={ref}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '48px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'full',
        backgroundColor: 'alpha.black.900',
        color: 'white',
        opacity: isPlaying ? 0 : '1',
        cursor: 'pointer',
        transition: 'all 0.25s ease-in-out',
        border: 'none',

        '&:hover, &:focus': {
          color: 'yellow',
        },
        ...(theme.video?.mainPlayButton || {}),
      }}
      {...props}
    ></button>
  );
});

export default MainPlayButton;
