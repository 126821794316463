/** @jsxRuntime classic */
/** @jsx jsx */
import { LogError } from 'business/constants';
import ContentBlock from 'components/blocks/content-block';
import ArrowLink from 'components/ui-components/arrow-link';
import { Fragment } from 'react';
import useSWR from 'swr';
import { Grid, jsx } from 'theme-ui';
import { BlockContainer } from 'utils/helpers/createBlocks';

const request = {
  params: {},
  query: `{
    'careerpage': *[_type == 'careerpage' && _id == 'careerpage']{
      slug,
    }[0],
    'openings': *[_type == 'jobopening' && !(_id in path("drafts.**"))] | order(publishedAt desc) {
      title,
      _id,
      slug,
      department,
      body,
      publishedAt,
    }
  }`,
};

export default function CareerList({ headline, body }) {
  const { data, error } = useSWR([request.query.toString(), request.params]);

  if (error || !data) {
    error && LogError(request.query, request.params, error);
    return null;
  }

  return (
    <Fragment>
      {headline && (
        <BlockContainer>
          <ContentBlock headline={headline} body={body} />
        </BlockContainer>
      )}
      <Grid
        columns={1}
        sx={{
          flexDirection: 'column',
          paddingX: [3, `${100 / 12}vw`],
          paddingY: [3, 5, 5],
        }}
        data-scroll
        data-scroll-repeat
      >
        {data?.openings?.map((c) => (
          <ArrowLink
            key={c._id}
            to={`${data?.careerpage?.slug?.current || 'careers'}/${
              c.slug.current
            }`}
            sx={{ lineHeight: 1 }}
          >
            {c.title}
          </ArrowLink>
        ))}
      </Grid>
    </Fragment>
  );
}
