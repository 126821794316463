/** @jsxRuntime classic */
/** @jsx jsx */
import { useResponsiveValue } from '@theme-ui/match-media';
import { useEffect, useRef } from 'react';
import { jsx } from 'theme-ui';
import { buildImageObj, getPaddingFromAspectRatio } from 'utils/helpers';
import urlFor from 'utils/helpers/image-url';

const getImageUrl = (image, width, height, grayscale) => {
  if (!image) return;
  let imageUrl = urlFor(buildImageObj(image)).fit('crop').auto('format');

  if (width) imageUrl = imageUrl.width(width);

  if (height) imageUrl = imageUrl.height(height);

  if (image.flipX) imageUrl = imageUrl.flipHorizontal();

  if (image.flipY) imageUrl = imageUrl.flipVertical();

  if (grayscale) imageUrl = imageUrl.saturation(-100);

  return imageUrl.url();
};

const resolveFormatPadding = (format, width) => {
  if (format === 'landscape') {
    // 16:9
    return getPaddingFromAspectRatio(width || 300, 1.77);
  }
  if (format === 'widescreen') {
    // ???
    return getPaddingFromAspectRatio(width || 300, 2);
  }
  if (format === 'portrait') {
    // ???
    return getPaddingFromAspectRatio(width || 300, 0.7625);
  }
  if (format === 'square') {
    // 1:1
    return getPaddingFromAspectRatio(width || 300, 1);
  }
  if (format === 'panorama') {
    // 24:9
    return getPaddingFromAspectRatio(width || 300, 2.66666667);
  }
};

/**
  In the groq query for this to work you need to follow the reference, like this:
  site_image{
    asset->{
      ...
    },
  },
 */
function Image(props) {
  const {
    image_asset,
    sx,
    height,
    width,
    grayscale,
    format,
    paddingTop,
    onLoad,
    mobileImage,
    ...rest
  } = props;
  const image = useResponsiveValue([
    image_asset?.mobileImage || image_asset,
    image_asset,
  ]);
  const { asset, crop } = image || {};
  const { dimensions, lqip, isOpaque } = asset?.metadata || {};

  const containerRef = useRef(null);
  const tinyRef = useRef(null);

  useEffect(() => {
    const el = containerRef.current;

    if (!el) {
      return undefined;
    }
    const rect = el.getBoundingClientRect();

    if (paddingTop) {
      el.style.paddingTop = paddingTop;
      return;
    }

    // forced formatting
    if (format) {
      let padding = resolveFormatPadding(format, rect.width);
      if (padding) {
        el.style.paddingTop = padding;
        return;
      }
    }

    // default 16:9
    if (!dimensions) {
      const padding = getPaddingFromAspectRatio(rect?.width || 300, 1.77);
      el.style.paddingTop = padding;
    }

    // resolve format from dimensions
    if (dimensions && dimensions.aspectRatio && dimensions.width) {
      let width = dimensions.width;
      let height = dimensions.height;

      if (crop) {
        const { bottom, left, right, top } = crop || {};
        const verticalCrop = bottom + top;
        const horizontalCrop = left + right;

        width = width * (1 - horizontalCrop);
        height = height * (1 - verticalCrop);
      }

      let aspectRatio = width / height;

      const padding = getPaddingFromAspectRatio(
        rect?.width || dimensions.width,
        aspectRatio,
      );
      el.style.paddingTop = padding;
    }
  }, [format, paddingTop, dimensions]);

  return (
    <div
      sx={{ overflow: 'hidden', maxHeight: '100%' }}
      data-scroll
      data-scroll-repeat
      {...rest}
    >
      <div
        ref={containerRef}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          ...sx,
        }}
      >
        {lqip && isOpaque && (
          // low res image, is removed when high res image is done loading.
          <div
            sx={{
              objectFit: 'cover',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              height: '100%',
              backgroundImage: `url(${lqip})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              filter: `blur(10px)`,
            }}
            ref={tinyRef}
          />
        )}
        <Img
          alt={image?.alt || 'sorry, no alt text :('}
          asset={image}
          sx={{
            objectFit: 'cover',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            height: '100%',
          }}
          width={width}
          height={height}
          grayscale={grayscale}
          onLoad={(e) => {
            tinyRef.current && (tinyRef.current.style.display = 'none');
            onLoad && onLoad(e);
          }}
        />
      </div>
    </div>
  );
}

export const Img = (props) => {
  const { asset, width, height, grayscale, sx, alt, ...rest } = props;
  let imageUrl = asset ? getImageUrl(asset, width, height, grayscale) : '';

  return (
    <img
      alt={asset?.alt || alt}
      src={imageUrl}
      loading="lazy"
      sx={{ width: '100%', ...sx }}
      {...rest}
    />
  );
};

export default Image;
