export const VideoStyles = {
  progressBarHeight: '10px',
  trackProgressColor: '#F5C701',
  trackBackground: 'rgba(255,255,255,0.2)',
  trackHeight: '4px',
  thumbSize: '8px',
  thumbColor: 'white',
  iconSize: 20,
}

export default VideoStyles
