import { useEffect } from 'react';
import gsap from 'gsap';

// fades in all objects with attribute 'data-fadein'
export default function useFadeIn() {
  useEffect(() => {
    var timeline = gsap.timeline({ delay: 0.8 });

    timeline.fromTo(
      '[data-fadein=true]',
      {
        y: 30,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        ease: 'Power2.easeInOut',
        stagger: 0.05,
      },
    );
    timeline.play();
  }, []);
}

// fade out on leaving pages, used in navigation links in header
export const fadeOut = () => {
  var timeline = gsap.timeline();
  timeline.fromTo(
    // '[data-fadein=true]',
    '.is-inview',
    {
      // y: 0,
      opacity: 1,
    },
    {
      // y: 50,
      opacity: 0,
      duration: 0.4,
      ease: 'Power2.easeInOut',
    },
  );
  timeline.play();
};
