/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import { forwardRef } from 'react';

export const TrackProgress = forwardRef((props, ref) => {
  const { theme = {} } = useThemeUI();

  return (
    <div
      data-video-play-progress
      ref={ref}
      aria-hidden="true"
      sx={{
        borderRadius: 'full',
        height: '100%',
        pointerEvents: 'none',
        position: 'absolute',
        top: '0',
        maxWidth: 'none',
        transition: 'all 0.2s ease',
        backgroundColor: '#F5C701',
        ...(theme.video?.progressBar?.track?.progress || {}),

        '&::before': {
          content: '""',
          borderRadius: '100%',
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translate(50%, -50%)',
          height: '8px',
          width: '8px',
          backgroundColor: 'white',
          ...(theme.video?.progressBar?.track?.thumb || {}),
        },
      }}
      {...props}
    />
  );
});

export default TrackProgress;
