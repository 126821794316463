/** @jsxRuntime classic */
/** @jsx jsx */
import { Link } from 'components/ui-components/link';
import { forwardRef } from 'react';
import { jsx, Text } from 'theme-ui';

/**
 * Link that shows an arrow to the left when hovered.
 */
const baseStyles = {
  position: 'relative',
  transition: 'all .3s',
  fontSize: [6, 6, 6, 7],

  ':before': {
    content: '""',
    width: ['5.8rem', null, null, '7.5rem'],
    height: ['5.1rem', null, null, '7rem'],
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='58' height='51' %3E%3Cpath d='M29.454 3.99L51.06 23.614l-13.773-.26-36.316.634.068 3.93 36.318-.563 13.683-.745-20.833 20.442 3 2.878 23.799-24.419-.018-1L32.352.938 29.454 3.99z' fill='black'/%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
    left: [-5, null, null, '-9rem'],
    opacity: 0,
    transition: 'all .3s',
  },

  '&:hover': {
    paddingLeft: [5, null, null, '9rem'],
    ':before': {
      opacity: 1,
      left: 0,
    },
  },
};

export const ArrowLink = forwardRef((props, ref) => {
  if (props.as === 'a' || !props.as) {
    return (
      <Link
        variant="preamble"
        sx={{
          ...baseStyles,
        }}
        ref={ref}
        {...props}
      />
    );
  }

  return (
    <Text
      variant="preamble"
      sx={{
        ...baseStyles,
      }}
      ref={ref}
      {...props}
    />
  );
});

export default ArrowLink;
