/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { keyframes } from '@emotion/core';
import { useEffect, useState } from 'react';
import Icon from 'components/ui-components/icon';

const rotation = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(-359deg)',
  },
});

function LoadingSpinner(props) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let loadedTimer = setTimeout(() => setIsLoaded(true), 1000);

    return () => {
      clearTimeout(loadedTimer);
    };
  }, []);

  return (
    <Icon
      __css={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-5rem 0 0 -5rem',
        transformOrigin: '50% 50%',
        transition: 'opacity .7s ease-in',
        animationName: rotation.toString(),
        animationTimingFunction: 'linear',
        animationDuration: '10s',
        animationIterationCount: 'infinite',
        opacity: isLoaded ? 1 : 0,
        color: 'black',
        maxWidth: '10rem',
      }}
      symbol="spinner"
      {...props}
    />
  );
}

export default LoadingSpinner;
