/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Heading, jsx } from 'theme-ui';

export const Tagline = (props) => {
  const { pre, tagline, ...rest } = props;
  return (
    <Box
      sx={{
        marginBottom: [3, 3, 0],
        minWidth: 'initial',
      }}
      {...rest}
    >
      {pre ? (
        <Heading
          data-scroll
          data-scroll-repeat
          as="span"
          variant="h5"
          sx={{
            paddingLeft: 4,
            paddingRight: 4,
            display: 'block',
            position: 'relative',
            fontSize: [2, 3, 4],
            marginBottom: [1, null, 2],
            marginTop: [null, null, 2],

            ':before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              transform: 'translateY(-50%)',
              top: '50%',
              left: 0,
              width: '2rem',
              height: 1,
              background: 'black',
            },
          }}
        >
          {pre}
        </Heading>
      ) : null}
      {tagline && (
        <Heading
          data-scroll
          data-scroll-repeat
          as="span"
          variant="h5"
          sx={{ display: 'block', fontSize: [2, 3, 4] }}
        >
          {tagline}
        </Heading>
      )}
    </Box>
  );
};
