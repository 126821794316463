/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const strokeStyle = { vectorEffect: 'none', strokeWidth: '2' };

const HamburgerIcon = (props) => (
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path d="M5 7.5H20" stroke="currentColor" style={strokeStyle} />
    <path d="M5 12.5H20" stroke="currentColor" style={strokeStyle} />
    <path d="M5 17.5H20" stroke="currentColor" style={strokeStyle} />
  </svg>
);

export default HamburgerIcon;
