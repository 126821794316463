/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const Pause = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18.75 3.75h-3.375a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h3.375a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75zM8.625 3.75H5.25a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h3.375a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pause;
