/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const ArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 27" {...props}>
    <path
      d="M18.533 1.654l13.666 11.02-8.85-.277H0v2.166l23.35.04 8.803-.28-13.62 11.063L20.43 27 36 13.776v-.552L20.43 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowIcon;
