export const breakpoints = ['600px', '960px', '1280px', '1920px'];

const sizes = {
  px: '1px',
  0: '0',
  1: '0.25rem',
  2: '0.5rem',
  3: '0.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  8: '2rem',
  10: '2.5rem',
  12: '3rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  32: '8rem',
  40: '10rem',
  48: '12rem',
  56: '14rem',
  64: '16rem',
  82: '18rem',
  xs: '24rem',
  sm: '32rem',
  md: '48rem',
  lg: '66rem',
  xl: '82rem',
  '1/2': '50%',
  '1/3': '33.333333%',
  '2/3': '66.666667%',
  '1/4': '25%',
  '2/4': '50%',
  '3/4': '75%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '1/6': '16.666667%',
  '2/6': '33.333333%',
  '3/6': '50%',
  '4/6': '66.666667%',
  '5/6': '83.333333%',
  '1/12': '8.333333%',
  '2/12': '16.666667%',
  '3/12': '25%',
  '4/12': '33.333333%',
  '5/12': '41.666667%',
  '6/12': '50%',
  '7/12': '58.333333%',
  '8/12': '66.666667%',
  '9/12': '75%',
  '10/12': '83.333333%',
  '11/12': '91.666667%',
  full: '100%',
  screenHeight: '100vh',
  screenWidth: '100vw',
  container: '192rem',
};

export const colors = {
  text: '#000',
  black: '#000',
  white: '#FAFBFF',
  background: '#E8E0D8',
  orange: '#C06317',
  yellow: '#F5C701',
  red: '#F16D70',
  pink: '#F6CEDB',
  purple: '#ACA0CC',
  green: '#BDCDCB',
  blue: '#88C6DB',
  darkblue: '#1E2368',
  darkgreen: '#125C5B',
  alpha: {
    black: {
      900: 'rgba(0,0,0,0.9)',
      800: 'rgba(0,0,0,0.8)',
      700: 'rgba(0,0,0,0.7)',
      600: 'rgba(0,0,0,0.6)',
      500: 'rgba(0,0,0,0.5)',
      400: 'rgba(0,0,0,0.4)',
      300: 'rgba(0,0,0,0.3)',
      200: 'rgba(0,0,0,0.2)',
      100: 'rgba(0,0,0,0.1)',
    },
    white: {
      900: 'rgba(255,255,255,0.9)',
      800: 'rgba(255,255,255,0.8)',
      700: 'rgba(255,255,255,0.7)',
      600: 'rgba(255,255,255,0.6)',
      500: 'rgba(255,255,255,0.5)',
      400: 'rgba(255,255,255,0.4)',
      300: 'rgba(255,255,255,0.3)',
      200: 'rgba(255,255,255,0.2)',
      100: 'rgba(255,255,255,0.1)',
    },
  },
};

export const space = [
  '0',
  '0.4rem',
  '0.8rem',
  '1.6rem',
  '3.2rem',
  '6.4rem',
  '12.8rem',
  '25.6rem',
  '51.2rem',
];

export const theme = {
  breakpoints,
  sizes,
  colors,
  space,
  fonts: {
    regular: 'Faktum-Regular',
    medium: 'Faktum-Medium',
    semibold: 'Faktum-SemiBold',
    bold: 'Faktum-Bold',
    body:
      '"Faktum-Regular", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Faktum-SemiBold',
    navlink: 'Faktum-Medium',
    monospace: 'Menlo, monospace',
  },
  // TODO: Change to REM
  fontSizes: {
    0: 12,
    1: 14,
    2: 16,
    3: 20,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
    8: 72,
    9: 120,
    10: 160,
    11: 200,
    preamble: 62,
    body: 30,
    caption: 20,
  },
  fontWeights: {
    body: 400,
    heading: 600,
    display: 600,
  },
  lineHeights: {
    body: 1.25,
    heading: 1.1,
  },
  textStyles: {
    heading: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'normal',
      letterSpacing: '-0.03ch',
    },
    body: {
      color: 'inherit',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'normal',
    },
  },
  text: {
    default: {
      color: 'text',
      fontSize: [3, null, 4],
    },
    h1: {
      variant: 'textStyles.heading',
      fontSize: [5, 6, 7],
    },
    h2: {
      variant: 'textStyles.heading',
      fontSize: [4, 5, 6],
    },
    h3: {
      variant: 'textStyles.heading',
      fontSize: [3, 4, 5],
    },
    h4: {
      variant: 'textStyles.heading',
      fontSize: [3, 4, 4],
    },
    h5: {
      variant: 'textStyles.heading',
      fontSize: [3, 4, 4],
    },
    h6: {
      variant: 'textStyles.heading',
      fontSize: [3, 4, 4],
    },
    preamble: {
      variant: 'textStyles.body',
      fontSize: [4, 5, 6],
      marginBottom: ['2rem', null, '0'],
      letterSpacing: '-0.05ch',
      lineHeight: 1.1,
    },
    body: {
      variant: 'textStyles.body',
      fontSize: [3, null, null, 4],
      marginBottom: 4,
    },
    display: {
      variant: 'textStyles.body',
      // ['0', '600px', '960px', '1280px', '1920px'];
      fontSize: [5, 6, 60, null, 8],
      letterSpacing: '-0.05ch',
      lineHeight: 1.1,
    },
    strong: {
      variant: 'textStyles.heading',
      fontFamily: 'medium',
    },
  },
  styles: {
    Container: {
      maxWidth: 'sizes.container',
    },
    root: {
      fontSize: [3, 4, 5],
      fontFamily: 'body',
      lineHeight: 'body',
      '[data-scroll="true"]': {
        opacity: 0,
        transition: 'opacity 1s ease-in-out',
      },
      '.is-inview': {
        opacity: 1,
        transition: 'opacity 1s ease-in-out',
      },
      strong: {
        fontFamily: 'bold',
        fontWeight: 'normal',
      },
    },
    a: {
      color: 'inherit',
    },
    img: {
      maxWidth: '100%',
    },
  },
  links: {
    richtext: {
      textDecoration: 'underline',
    },
  },
  borders: {
    default: '0.2rem solid black',
  },
  borderWidths: {
    default: '0.2rem',
  },
  radii: {
    none: '0',
    sm: 4,
    md: 7,
    lg: 10,
    full: '9999px',
  },
};

export default theme;
