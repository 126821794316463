/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const PictureInPicture = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20.25 5.25H3.75A.75.75 0 003 6v12c0 .414.336.75.75.75h16.5A.75.75 0 0021 18V6a.75.75 0 00-.75-.75z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 18.75v-6a.75.75 0 01.75-.75H21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PictureInPicture;
