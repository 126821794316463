/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui';
import Image from 'components/ui-components/image';

const ImageGridBlock = (props) => {
  return (
    <Grid
      sx={{
        paddingX: [0, `${100 / 12}vw`],
        gridTemplateColumns: [
          'repeat(2, 1fr)',
          'repeat(4, 1fr)',
          'repeat(6, 1fr)',
        ],
        gap: 0,
      }}
    >
      {props.gridimages.map((image, i) => {
        return (
          <Box
            key={image._key || i}
            sx={{
              borderBottom: 'default',
              borderRight: 'default',
              display: 'block',
              maxWidth: '100%',
              padding: 3,
              margin: 0,
              '&:nth-of-type(2n)': {
                borderRight: ['none', 'default', null],
              },
              '&:nth-last-of-type(-n + 2)': {
                borderBottom: ['none', 'default', null],
              },
              '&:nth-of-type(4n)': {
                borderRight: [null, 'none', 'default'],
              },
              '&:nth-last-of-type(-n + 4)': {
                borderBottom: [null, 'none', 'default'],
              },
              '&:nth-of-type(6n)': {
                borderRight: [null, null, 'none'],
              },
              '&:nth-last-of-type(-n + 6)': {
                borderBottom: [null, null, 'none'],
              },
            }}
          >
            <Image
              key={image._key || i}
              image_asset={image}
              sx={{
                objectFit: 'contain',
              }}
            />
          </Box>
        );
      })}
    </Grid>
  );
};

export default ImageGridBlock;
