/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { jsx, useThemeUI } from 'theme-ui';
import useEventListener from '../../../utils/hooks/useEventListener.js';
import { PlayCircle } from '../icon';
import { MainPlayButton } from './MainPlayButton';
import {
  attachVideo,
  hideControls,
  showControls,
  toggleFullScreen,
  togglePlay,
} from './utilities';
import { VideoControls } from './VideoControls';

const handleError = ({ error, setState }) => {
  setState((prevState) => ({ ...prevState, error }));
};

export const Video = ({
  src,
  posterUrl,
  autoload,
  storyboard,
  onClick,
  controls,
  autoplay,
  isBackground,
  poster,
  ...props
}) => {
  const [state, setState] = useState({
    isLoading: true,
    error: null,
    useCustomControls: false,
    isPlaying: false,
    volume: isBackground ? 0 : 0.6,
    controlsVisible: false,
    autoPlayFailed: false,
  });
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const hlsRef = useRef(null);

  // show/hide custom controls
  useEffect(() => {
    // if html5 video is supported, if it is - display custom controls
    const videoWorks = !!document.createElement('video').canPlayType;

    if (videoRef.current) {
      if (videoWorks) {
        setState((prevState) => {
          return { ...prevState, useCustomControls: true };
        });
      }
    }
  }, []);

  useEventListener(
    'mouseenter',
    () =>
      showControls({
        videoEl: videoRef.current,
        setState,
      }),
    videoRef.current,
  );

  useEventListener(
    'mouseleave',
    () =>
      hideControls({
        setState,
      }),
    videoRef.current,
  );

  // handle background video
  useEffect(() => {
    if (videoRef.current) {
      if (isBackground) {
        videoRef.current.muted = true;
        videoRef.current.loop = true;
      }
    }
  }, [isBackground]);

  // play/pause events
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onpause = () =>
        setState((prevState) => ({
          ...prevState,
          isPlaying: false,
        }));
      videoRef.current.onplaying = () =>
        setState((prevState) => ({
          ...prevState,
          isPlaying: true,
        }));
    }
  }, []);

  // attach video
  useEffect(() => {
    if (videoRef.current) {
      attachVideo({
        hls: hlsRef.current,
        video: videoRef.current,
        videoContainer: videoContainerRef.current,
        setState: setState,
        onError: handleError,
        autoplay: isBackground,
        autoload,
        src,
      });
    }
  }, [autoload, isBackground, src]);

  const { theme = {} } = useThemeUI();

  if (state.error) {
    if (state.error.fatal) {
      return <p>{JSON.stringify(state.error)}</p>;
    } else {
      console.debug('video non-fatal error: ', state.error);
    }
  }

  return (
    <div
      ref={videoContainerRef}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        ...(theme.video?.container || {}),
      }}
      {...props}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        onClick={() => {
          togglePlay(videoRef.current);
          if (onClick && typeof onClick === 'function') onClick();
        }}
        onVolumeChange={() =>
          setState((prev) => {
            return { ...prev, volume: videoRef.current.volume };
          })
        }
        playsInline
        controls={!state.useCustomControls}
        poster={poster}
        preload="metadata"
        ref={videoRef}
        sx={{
          cursor: 'pointer',
          display: 'block',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        }}
        {...(isBackground ? { muted: '' } : {})}
      ></video>
      {state.useCustomControls && !isBackground && (
        <VideoControls
          videoEl={videoRef.current}
          state={state}
          storyboard={storyboard}
          toggleFullScreen={() =>
            toggleFullScreen({ element: videoContainerRef.current })
          }
          hideControls={() => hideControls({ setState })}
          showControls={() =>
            showControls({ videoEl: videoRef.current, setState })
          }
          togglePlay={() => togglePlay(videoRef.current)}
          controls={controls}
        />
      )}
      {state.useCustomControls && (state.autoPlayFailed || !isBackground) && (
        <MainPlayButton
          isPlaying={state.isPlaying}
          onClick={() => {
            togglePlay(videoRef.current);
          }}
          sx={{ zIndex: 10 }}
        >
          <PlayCircle
            hideOuterCircle={true}
            sx={{ color: 'white', zIndex: 10, width: '100%' }}
          />
        </MainPlayButton>
      )}
    </div>
  );
};

Video.defaultProps = {
  autoload: true,
  autoplay: false,
  isBackground: false,
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  autoload: PropTypes.bool,
  onReady: PropTypes.func,
  autoplay: PropTypes.bool,
  isBackground: PropTypes.bool,
};

export default Video;
