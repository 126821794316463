/** @jsxRuntime classic */
/** @jsx jsx */
import BlockContent from 'components/blocks/block-content';
import { Tagline } from 'components/typography/tagline';
import ContentLayout from 'components/ui-components/layout/ContentLayout';
import { Box, Flex, Heading, jsx } from 'theme-ui';
import { resolveSize } from 'utils/helpers';

const ContentBlock = ({
  verticalspacingbottom,
  verticalspacingtop = 8,
  headline,
  body,
  alignWithTagline = false,
  sx,
  className,
  grid,
  ...props
}) => {
  const { decorator, tagline, headline_size } = headline || {};

  const resolvedSize = resolveSize(headline_size);

  return (
    <ContentLayout
      sx={{
        position: 'relative',
        margin: 0,
        paddingX: !grid ? [0, `${100 / 12}vw`] : 0,
        marginTop: [
          `${Math.round(verticalspacingtop / 3)}rem`,
          `${Math.round(verticalspacingtop / 2)}rem`,
          `${verticalspacingtop}rem`,
        ],
        marginBottom: [
          `${Math.round(verticalspacingbottom / 3)}rem`,
          `${Math.round(verticalspacingbottom / 2)}rem`,
          `${verticalspacingbottom}rem`,
        ],
        maxWidth: 'none',
        ...sx,
      }}
      {...props}
    >
      <Flex
        sx={{
          justifyContent: 'flex-start',
          flexDirection: ['column', null, 'row'],
          alignItems: 'flex-start',
        }}
      >
        {decorator || tagline ? (
          <Tagline
            data-scroll
            data-scroll-repeat
            pre={decorator}
            tagline={tagline}
            sx={{ flex: '4' }}
          />
        ) : (
          <Box sx={{ flex: '4' }} />
        )}
        <Box sx={{ flex: '1' }} />
        <Box
          sx={{
            flex: '15',
          }}
        >
          <Box sx={{ maxWidth: ['full', null, `60vw`] }}>
            {headline?.headline && (
              <Heading
                data-scroll
                data-scroll-repeat
                as="h1"
                variant="h1"
                sx={{ fontSize: resolvedSize, marginBottom: body ? [4] : null }}
              >
                {headline.headline}
              </Heading>
            )}
            {body && !alignWithTagline ? (
              <BlockContent
                data-scroll
                data-scroll-repeat
                blocks={body || []}
              />
            ) : null}
          </Box>
        </Box>
      </Flex>
      {body && alignWithTagline ? (
        <BlockContent data-scroll data-scroll-repeat blocks={body || []} />
      ) : null}
    </ContentLayout>
  );
};

export default ContentBlock;
