/** @jsxRuntime classic */
/** @jsx jsx */
import { Grid, Container, jsx } from 'theme-ui';
import { GRID_COLUMNS, GRID_ROWS } from 'utils/constants';
import ContentLayout from 'components/ui-components/layout/ContentLayout';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export const GridColumnOverlay = ({
  columns = GRID_COLUMNS,
  color,
  opacity = 0.1,
  size = '1px',
  fixed = true,
}) => {
  const [queryString, setqueryString] = useState({});

  let location = useLocation();

  useEffect(() => {
    const values = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    values && setqueryString(values);
  }, [location.search]);

  if (!queryString?.debug) {
    return null;
  }

  return (
    <Container
      sx={{
        // paddingLeft: [3, `${100 / GRID_COLUMNS}vw`],
        // paddingRight: [3, `${100 / GRID_COLUMNS}vw`],
        height: fixed ? '100vh' : '100%',
        margin: '0 auto',
        width: '100%',
        position: fixed ? 'fixed' : 'absolute',
        top: 0,
        bottom: 0,
        pointerEvents: 'none',
        display: 'block',
        maxWidth: 'none',
        zIndex: 9999,
      }}
    >
      <Grid
        sx={{
          maxWidth: '100%',
          width: '100%',
          height: '100%',
          position: 'relative',
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
          gap: 0,
          boxSizing: 'border-box',
          span: {
            opacity: opacity,
            borderLeft: `${size} solid ${color || 'black'}`,

            ':last-of-type': {
              borderRight: `${size} solid ${color || 'black'}`,
            },
          },
        }}
      >
        {Array(columns)
          .fill()
          .map(() => {
            return <span key={Math.random().toString(36).substr(2, 5)} />;
          })}
      </Grid>
    </Container>
  );
};

export const GridRowOverlay = ({
  rows = GRID_ROWS,
  color,
  opacity = 0.1,
  size = '1px',
}) => {
  const [queryString, setqueryString] = useState({});

  let location = useLocation();

  useEffect(() => {
    const values = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    values && setqueryString(values);
  }, [location.search]);

  if (!queryString?.debug) {
    return null;
  }

  return (
    <Container
      sx={{
        height: '100%',
        margin: '0 auto',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'block',
        maxWidth: 'none',
      }}
    >
      <ContentLayout
        sx={{
          maxWidth: '100%',
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'grid',
          gridTemplateRows: `repeat(${rows}, 1fr)`,
          gap: 0,
          boxSizing: 'border-box',
          span: {
            opacity: opacity,
            borderBottom: `${size} solid ${color || 'black'}`,

            ':first-of-type': {
              borderTop: `3px solid red`,
            },
          },
        }}
      >
        {Array(rows)
          .fill()
          .map(() => {
            return <span key={Math.random().toString(36).substr(2, 5)} />;
          })}
      </ContentLayout>
    </Container>
  );
};

export default GridColumnOverlay;
