import Hls from 'hls.js';

export const formatTime = (timeInSeconds) => {
  if (!timeInSeconds) {
    return {
      minutes: '00',
      seconds: '00',
    };
  }
  const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

  return {
    minutes: result.substr(3, 2),
    seconds: result.substr(6, 2),
  };
};

export const normalize = (val, min, max) => {
  // Shift to positive to avoid issues when crossing the 0 line
  if (min < 0) {
    max += 0 - min;
    val += 0 - min;
    min = 0;
  }
  // Shift values from 0 - max
  val = val - min;
  max = max - min;
  return Math.max(0, Math.min(1, val / max));
};

export const attachVideo = ({
  hls,
  src,
  video,
  autoload,
  onReady,
  onError,
  setState,
  autoplay,
}) => {
  if (Hls.isSupported()) {
    hls = new Hls({ autoStartLoad: autoload });
    hls.loadSource(src);
    hls.attachMedia(video);

    hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(src);
      hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        onReady && onReady();

        if (autoplay) {
          video
            .play()
            .then(function () {
              // autoplay was successful!
            })
            .catch(function (error) {
              // autoplay failed
              setState((prev) => ({ ...prev, autoPlayFailed: true }));
            });
        }
      });
    });

    hls.on(Hls.Events.ERROR, (event, error = {}) => {
      if (error.fatal) {
        switch (error.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            // eslint-disable-next-line no-console
            console.warning('fatal network error encountered, try to recover');
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            // eslint-disable-next-line no-console
            console.warning('fatal media error encountered, try to recover');
            hls.recoverMediaError();
            break;
          default:
            // cannot recover
            hls.destroy();
            break;
        }
      }
      onError && onError({ error, setState });
    });
  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = src;

    if (autoplay) {
      video
        .play()
        .then(function () {
          // autoplay was successful!
        })
        .catch(function (error) {
          // autoplay failed
          setState((prev) => ({ ...prev, autoPlayFailed: true }));
        });
    }

    video.addEventListener('loadedmetadata', () => {
      hls.loadSource(src);
      hls.attachMedia(video);

      if (autoplay) {
        video
          .play()
          .then(function () {
            // autoplay was successful!
          })
          .catch(function (error) {
            // autoplay failed
            setState((prev) => ({ ...prev, autoPlayFailed: true }));
          });
      }
    });

    video.addEventListener('canplay', () => {
      if (autoplay) {
        video
          .play()
          .then(function () {
            // autoplay was successful!
          })
          .catch(function (error) {
            // autoplay failed
            setState((prev) => ({ ...prev, autoPlayFailed: true }));
          });
      }
    });
  }
};

export const togglePlay = (video) => {
  if (!video) {
    return;
  }
  if (video.paused || video.ended) {
    video.play();
  } else {
    video.pause();
  }
};

export const toggleMute = ({ video, volumeSlider }) => {
  video.muted = !video.muted;
  if (video.muted) {
    volumeSlider.setAttribute('data-volume', video.volume);
    volumeSlider.value = 0;
    video.volume = 0;
  } else {
    volumeSlider.value = volumeSlider.dataset.volume;
    video.volume = Number(volumeSlider.dataset.volume);
  }
};

export const toggleFullScreen = ({ element }) => {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else if (document.webkitFullscreenElement) {
    // Need this to support Safari
    document.webkitExitFullscreen();
  } else if (element.webkitRequestFullscreen) {
    // Need this to support Safari
    element.webkitRequestFullscreen();
  } else {
    element.requestFullscreen();
  }
};

// updateVolume updates the video's volume
// and disables the muted state if active
export const updateVolume = ({ videoEl, volumeSlider }) => {
  if (!videoEl) {
    return;
  }

  if (videoEl.muted) {
    videoEl.muted = false;
  }
  videoEl.volume = Number(volumeSlider.value).toFixed(2);
};
// togglePip toggles Picture-in-Picture mode on the video
export const togglePip = async ({ videoEl, pipButtonEl }) => {
  try {
    if (videoEl !== document.pictureInPictureElement) {
      pipButtonEl.disabled = true;
      await videoEl.requestPictureInPicture();
    } else {
      await document.exitPictureInPicture();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    pipButtonEl.disabled = false;
  }
};

// hideControls hides the video controls when not in use
// if the video is paused, the controls must remain visible
export const hideControls = ({ setState }) => {
  setState((prev) => ({ ...prev, controlsVisible: false }));
};

// showControls displays the video controls
export const showControls = ({ videoEl, setState }) => {
  if (videoEl.paused) {
    return;
  }

  setState((prev) => ({ ...prev, controlsVisible: true }));
};

export const setupControls = ({
  videoEl,
  durationEl,
  progressBarEl,
  // seekEl,
}) => {
  if (videoEl && durationEl) {
    const videoDuration = Math.round(videoEl.duration);
    // seekEl.setAttribute('aria-valuemax', videoDuration)
    progressBarEl.setAttribute('aria-valuemax', videoDuration);

    const time = formatTime(videoDuration);
    progressBarEl.setAttribute(
      'aria-valuetext',
      `00:00 of ${time.minutes}:${time.seconds}`,
    );
    durationEl.innerText = `${time.minutes}:${time.seconds}`;
    durationEl.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
  }
};

/** ---- PROGRESSBAR START ---- */
export const updateProgress = ({ videoEl, seekEl, progressBarEl }) => {
  const normalizedProgress = normalize(
    videoEl.currentTime,
    0,
    Math.round(videoEl.duration),
  );
  seekEl.style.width = `${Math.floor(normalizedProgress * 100)}%`;
  progressBarEl.setAttribute('aria-valuenow', Math.floor(videoEl.currentTime));

  const currentTime = formatTime(Math.round(videoEl.currentTime));
  const durationTime = formatTime(Math.round(videoEl.duration));
  progressBarEl.setAttribute(
    'aria-valuetext',
    `${currentTime.minutes}:${currentTime.seconds} of ${durationTime.minutes}:${durationTime.seconds}`,
  );
};

export const updateTimeElapsed = ({ videoEl, elapsedTimeEl }) => {
  const time = formatTime(Math.round(videoEl.currentTime));
  elapsedTimeEl.innerText = `${time.minutes}:${time.seconds}`;
  elapsedTimeEl.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
};

// jumpToTime skips to a different point in the video when
// the progress bar is clicked
export const jumpToTime = ({ videoEl, progressBarEl }) => {
  const currentTime = videoEl.currentTime;
  const seekTarget = progressBarEl?.dataset?.seekTarget;
  const newTime = seekTarget && !isNaN(seekTarget) ? seekTarget : currentTime;

  videoEl.currentTime = newTime;
};

// handleSeek uses the position of the mouse on the progress bar to
// roughly work out what point in the video the user will skip to if
// the progress bar is clicked at that point
export const handleSeek = ({
  event,
  seekTooltipEl,
  seekToolTipTextEl,
  seekToolTipImageEl,
  seekToolTipImageTiles,
  progressBar,
}) => {
  const trackWidth = event.target.clientWidth;
  const hoverPositionX = event.offsetX;
  const normalized = normalize(hoverPositionX, 0, trackWidth).toFixed(2); // 0 - 1
  const positionInSeconds = (
    normalized * parseInt(progressBar.getAttribute('aria-valuemax'), 10)
  ).toFixed(2);

  // update time to possibly jump to, if jumpToTime is triggered.
  progressBar.setAttribute('data-seek-target', Math.round(positionInSeconds));

  const updateToolTipTime = () => {
    const t = formatTime(Math.round(positionInSeconds));
    if (seekToolTipTextEl)
      seekToolTipTextEl.textContent = `${t.minutes}:${t.seconds}`;
  };

  const moveToolTip = () => {
    const seekRect = progressBar.getBoundingClientRect();
    const tooltipRect = seekTooltipEl.getBoundingClientRect();
    const toolTipMarginX = 2;
    const tooltipHalf = tooltipRect.width / 2 + toolTipMarginX;
    let tooltipPosition = hoverPositionX;

    if (hoverPositionX < tooltipHalf) {
      tooltipPosition = tooltipHalf;
    }

    if (hoverPositionX > seekRect.width - tooltipHalf) {
      tooltipPosition = seekRect.width - tooltipHalf;
    }

    seekTooltipEl.style.left = `${tooltipPosition}px`;
  };

  const setupTimelinePreview = () => {
    // get the image tile that has the closest 'start' key value to the current
    // position in seconds and show it
    const currentTile = seekToolTipImageTiles.reduce((prev, curr) =>
      Math.abs(curr?.start - positionInSeconds) <
      Math.abs(prev?.start - positionInSeconds)
        ? curr
        : prev,
    );
    seekToolTipImageEl.style.backgroundPosition = `-${currentTile.x}px -${currentTile.y}px`;
  };

  updateToolTipTime();
  moveToolTip();

  if (seekToolTipImageTiles && seekToolTipImageEl) {
    setupTimelinePreview();
  }
};
