import React, { forwardRef } from 'react';
import { font, fontSizes } from 'styles';
import { Headline as headline } from './headline';
import { Paragraph as paragraph } from './paragraph';
import { Tagline as tagline } from './tagline';

export const Text = forwardRef(
  (
    {
      textColor,
      tag = 'span',
      fontFamily,
      center,
      caps,
      capitalize,
      nofade,
      ...props
    },
    ref,
  ) => {
    const Tag = tag;
    const textStyle = {
      color: textColor ? textColor : null,
      fontFamily: fontFamily ? fontFamily : null,
      textAlign: center ? 'center' : null,
      textTransform: caps ? 'uppercase' : capitalize ? 'capitalize' : null,
    };
    const mergedStyle = Object.assign(textStyle, props.style);

    return (
      <Tag
        {...props}
        data-fadein={nofade ? false : true}
        ref={ref}
        style={mergedStyle}
      />
    );
  },
);

export const Headline = headline;
export const Paragraph = paragraph;
export const Tagline = tagline;

export const Code = (props) => {
  const { className, ...rest } = props || {};
  return (
    <Text
      {...rest}
      className={`${fontSizes.body.default} ${className ? className : ''}`}
      tag="code"
      fontFamily={font.mono}
    />
  );
};
