/** @jsxRuntime classic */
/** @jsx jsx */
import { forwardRef } from 'react';
import { Flex, jsx } from 'theme-ui';

export const ToolTip = forwardRef((props, ref) => {
  return (
    <Flex
      data-seek-tooltip
      ref={ref}
      sx={{
        flexDirection: 'column',
        display: 'none',
        position: 'absolute',
        top: '0',
        transform: 'translateX(-50%) translateY(calc(-100% - 20px))',
        fontSize: 1,
        content: 'attr(data-title)',
        color: 'text.inverse',
        backgroundColor: 'alpha.black.600',
        textAlign: 'center',
        borderRadius: 'sm',
        overflow: 'hidden',
      }}
      {...props}
    />
  );
});

export default ToolTip;
