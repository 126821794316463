/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import { forwardRef } from 'react';

export const ProgressBar = forwardRef((props, ref) => {
  const { theme = {} } = useThemeUI();

  return (
    <div
      data-video-progress-control
      ref={ref}
      tabIndex="0"
      role="slider"
      aria-valuenow={0} // TODO
      aria-valuemin={0} // valuemax set in updateProgress
      aria-valuemax={0} // valuemax set in updateProgress
      aria-valuetext={''}
      sx={{
        width: '100%',
        height: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',

        '&:hover': {
          cursor: 'pointer',
          '[data-seek-tooltip]': {
            display: 'block',
          },
        },

        ...(theme.video?.progressBar?.container || {}),
      }}
      {...props}
    />
  );
});

export default ProgressBar;
