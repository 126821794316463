/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

const SpinnerIcon = (props) => (
  <Box
    as="svg"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <Box
      as="path"
      d="M5.5 35.5l.2-2.8c.2-2.2 1.7-3.5 3.8-3.4 2.1.2 3.4 1.7 3.2 3.9l-.2 2.8-7-.5zm5.7-2.3c.1-1.6-.3-2.1-1.9-2.2-1.6-.1-2.1.3-2.2 1.9L7 34.1l4.1.3.1-1.2zM13.5 20.3c1.9 1 2.5 3.1 1.6 4.9-.9 1.8-2.9 2.6-4.8 1.6s-2.5-3.1-1.6-4.9c.8-1.8 2.8-2.6 4.8-1.6zm-.8 1.4c-1.3-.7-2.2-.4-2.8.8-.6 1.2-.3 2.1 1 2.8 1.3.7 2.2.4 2.8-.8.6-1.2.3-2.1-1-2.8zM17.8 10.9l4.7 5.2-1.5 1.4-4.6-1.6-.9-.4 3.4 3.8-1.2 1-4.7-5.1 1.6-1.4 4.3 1.5 1 .4-3.2-3.7 1.1-1.1zM23.5 8.8c.1.2.2.4.1.9l-.1 1.6-1.1.5.5-1.7c-.5.2-.9 0-1.2-.5-.3-.5-.1-1 .5-1.2.6-.3 1.1-.1 1.3.4zM31.8 7l-1.9.4 1.2 5.4-1.5.3-1.2-5.4-1.9.4-.3-1.4 5.4-1.2.2 1.5zM44.3 8.4L42.4 8 41 13.4l-1.5-.4 1.3-5.4-1.9-.5.3-1.4L44.6 7l-.3 1.4zM53.3 11.8l-3.7 5.9-1.3-.8 1.5-2.4-2.5-1.6-1.5 2.4-1.3-.8 3.7-5.9 1.3.8-1.4 2.3 2.5 1.6L52 11l1.3.8zM56.4 14.8l1.1 1.2-5.2 4.6-1.1-1.2 5.2-4.6zM62.9 25.2l-6.3 3-.9-1.8 2.8-4 .6-.7-4.6 2.2-.7-1.4 6.3-3 .9 1.9-2.6 3.6-.7.9 4.5-2.1.7 1.4zM60.3 32.9l-1.5-1-1.6.1-.1-1.6 7-.6.1 1.6-3.8.3 1.5.6 2.5 1.5.2 2.1-2.9-2.1-4 2.7-.2-1.9 2.8-1.7zM46 60.7l-.5-1.3-2.5 1-.7-1.4 2.5-1-1-2.5 1.5-.6 2.6 6.5-4.1 1.7-.5-1.3 2.7-1.1zM38.3 62.7l-.1-1.2-2.8.3-.1-1.4 2.8-.3-.1-1.5-3 .3-.1-1.4 4.5-.4.7 7-4.5.4-.3-1.5 3-.3zM30 62.4l.2-1.2-2.8-.6.3-1.4 2.8.6.3-1.4-2.9-.6.3-1.4 4.5.9-1.4 6.8-4.5-.9.3-1.4 2.9.6zM21.5 54.7l.7-1.3 3.9 2.1-3.3 6.2-1.4-.7 2.6-4.9-2.5-1.4z"
    />
    <Box
      as="path"
      d="M35 69.9c-19.3 0-35-15.7-35-35S15.7 0 35 0s35 15.7 35 35-15.7 34.9-35 34.9zm0-68.2C16.7 1.7 1.8 16.6 1.8 35c0 18.3 14.9 33.2 33.2 33.2S68.3 53.3 68.3 35c0-18.4-15-33.3-33.3-33.3z"
    />
    <Box
      as="path"
      d="M51.5 56l-.8-1.5c5.1-2.9 8.1-7.6 8.8-14l1.7.2c-.8 6.8-4.2 12.1-9.7 15.3zM35 54.1c-10.6 0-19.2-8.6-19.2-19.2S24.4 15.8 35 15.8 54.2 24.4 54.2 35 45.6 54.1 35 54.1zm0-36.6c-9.6 0-17.5 7.8-17.5 17.5S25.4 52.4 35 52.4 52.5 44.6 52.5 35 44.6 17.5 35 17.5zM15.5 56.2c-.4 0-.7-.2-.8-.6 0-.1-.5-2 .4-3.5.6-.9 1.5-1.4 2.7-1.7.5-.1.9.2 1 .7.1.5-.2.9-.7 1-.8.1-1.3.4-1.6.9-.6.9-.2 2.1-.2 2.1.1.5-.1.9-.6 1.1-.1-.1-.1 0-.2 0zM9.3 43.8c-.8 0-1.6-.3-2.4-.9-.4-.3-.5-.8-.2-1.2.3-.4.8-.5 1.2-.2.6.5 1.2.7 1.7.5 1-.2 1.7-1.3 1.7-1.3.2-.4.8-.5 1.2-.3.4.2.5.8.3 1.2 0 .1-1.1 1.8-2.8 2.1-.3.1-.5.1-.7.1z"
    />
    <Box
      as="path"
      d="M16.3 51.9c-3.4-2.2-5.2-5.1-5.3-8.8-.4.2-.9.3-1.4.3h-.3c.1 4.2 2.1 7.6 6 9.9.3-.5.6-1 1-1.4z"
    />
  </Box>
);

export default SpinnerIcon;
